<template>
  <v-dialog
    v-model="joinModal"
    max-width="680"
    @keydown.esc="cancelModal"
    persistent
  >
    <v-card-title class="headline">
      <span class="headline">{{ $t('joinModal.title', {tAuctionCode: currentAuction.code}) }}</span>
    </v-card-title>
    <v-card-text>
      <span v-if="!(isJoined && !isJoinedInThisBrowserTab)">{{ $t('joinModal.text', {tcurrentAuctionCode: currentAuction ? currentAuction.code : ""}) }}</span>
      <v-alert
        v-if="isJoined && !isJoinedInThisBrowserTab"
        class="show-here mt-4"
        border="left"
        dense
        text
        type="error"
      >
        {{ $t('joinModal.alreadyJoinedOnThisDevice') }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="text-none" @click="cancelModal">{{ $t('No') }}</v-btn>
      <v-btn :disabled="isJoined && !isJoinedInThisBrowserTab" class="text-none" color="success" @click="submitModal">{{ $t('Yes') }}</v-btn>
    </v-card-actions>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { AlertFlavor } from '@/api/enums';
import { UserJoinedAuctionStatus } from '@/api/enums';

export default {
  name: 'JoinAuction',
  props: {},
  data: () => ({
    joinDisabled: false
  }),
  computed: {
    ...mapState(useAuctionStore, ['joinModal', 'currentAuction']),
    isJoined() {
      try {
        const auctionStore = useAuctionStore();
        const joinResults = auctionStore.joinedLiveAuctions.runningAuctionsSignedUpFor;
        const joinResult = joinResults.find(e => e.id === this.currentAuction.id && e.status === UserJoinedAuctionStatus.joined);
        return (joinResult !== undefined);
      } catch (e) {
        return false;
      }
    },
    isJoinedInThisBrowserTab() {
      try {
        const rootStore = useRootStore();
        const auctionStore = useAuctionStore();
        const joinResults = auctionStore.joinedLiveAuctions.runningAuctionsSignedUpFor;
        const joinResult = joinResults.find(e => e.id === this.currentAuction.id && e.status === UserJoinedAuctionStatus.joined && e.browserTabFingerprint == rootStore.browserTabId);
        return (joinResult !== undefined);
      } catch (e) {
        return false;
      }
    }
  },
  watch: {
    // evaluate every time the modal is opened
    async joinModal(val) {
      if (val) {
        try {
          await this.getAuctionJoinedState();
        } catch (e) {}
      }
    },
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_TEMP_ALERT']),
    ...mapActions(useAuctionStore, ['CHANGE_STATE', 'getAuctionJoinedState', 'joinAuction', 'leaveAuction']),
    async submitModal() {
      try {
        await this.joinAuction(this.currentAuction.id)

        if (this.$route.name === 'dashboard') {
          this.UPDATE_GLOBAL_STATE({key: 'showAuctionOverview', value: true})
        } else {
          this.$router.push({ name: 'dashboard' })
        }
        this.UPDATE_GLOBAL_STATE({ key: 'showAuctionOverview', value: false })
        await this.closeModal()
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error joining the auction. Please try again later') })
      }
    },
    async cancelModal() {
      try {
        if (this.isJoinedInThisBrowserTab) await this.leaveAuction(this.currentAuction.id, true) // exception handler inside
      } finally {
        await this.closeModal()
      }
    },
    async closeModal() {
      this.CHANGE_STATE({ key: 'joinModal', value: false })
    }
  }
}
</script>
<style lang="scss" scoped>
.primary-btn,
.secondary-btn {
  @media screen and (max-width: 968px) {
    max-width: calc(50% - 16px) !important;
    min-width: initial !important;
  }
}
</style>
