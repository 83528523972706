export enum Role {
  admin = 'admin',
  user = 'user',
  viewer = 'viewer',
  guest = 'guest',
  auctioneer = 'auctioneer',
  streamer = 'streamer'
}

export enum UserStatusType {
  pending = 'pending',
  locked = 'locked',
  unlocked = 'unlocked'
}

export enum BidderNumberStatusType {
  pending = 'pending',
  locked = 'locked',
  unlocked = 'unlocked'
}

export enum CommissionBidStatusType {
  pending = 'pending',
  accepted = 'accepted',
  declined = 'declined'
}

export enum AuctionFlavor {
  live = 'live',
  timed ='timed',
  sale = 'sale'
}

export enum BidderNumberType {
  live = 'live',
  timed ='timed',
  commissionBid = 'commission-bid',
  instantBuy = 'instant-buy'
}

export enum BidType {
  live = 'live',
  room = 'room',
  timed = 'timed',
  postAuction = 'post-auction',
  commission = 'commission',
  instantBuy = 'instant-buy'
}

export enum AuctionStatusType {
  preparing = 'preparing',
  started = 'started',
  stopped = 'stopped',
  paused = 'paused',
  completed = 'completed'
}

export enum SocketConnectionStatus {
  connected = 'connected',
  connectedReConnected = 'connected, reconnected',
  disconnectedDisconnecting = 'disconnected, reconnecting',
  disconnectedReconnectionError = 'disconnected, reconnection error',
  disconnectedReconnectingFailed = 'disconnected, reconnecting failed',
  disconnected = 'disconnected',
  init = 'init'
}

export enum SocketDisconnectReason {
  notDisconnected = 'socket not disconnected',
  ioServerDisonnect = 'io server disconnect',
  ioClientDisconnect = 'io client disconnect',
  pingTimeout = 'ping timeout',
  transportClose = 'transport close',
  transportError = 'transport error'
}

export enum SocketNamespace {
  admins = 'admins',
  users = 'users',
  guests = 'guests'
}

export enum UserJoinedAuctionStatus {
  notJoined = 'notJoined',
  joined = 'joined'
}

export enum PushEntity {
  user = 'user',
  article = 'article',
  auctionUser = 'auctionUser',
  articleDynamicData = 'articleDynamicData',
  category = 'category',
  message = 'message'
}

export enum PushType {
  create = 'create',
  update = 'update',
  delete = 'delete'
}

export enum ThemeType {
  dark = 'dark',
  light = 'light'
}

export enum AlertFlavor {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error'
}
