import Vue from 'vue'
// https://stackoverflow.com/questions/67615517/vuetify-difference-between-import-vuetify-lib-framework-vs-vuetify-lib
import Vuetify from 'vuetify/lib'
import * as directives from 'vuetify/lib/directives'
import config from '../configs'

import '@fortawesome/fontawesome-free/css/all.css'
import { ThemeType } from '@/api/enums';

Vue.use(Vuetify, {
  directives
})

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: config.theme.globalTheme === ThemeType.dark,
    options: {
      customProperties: true
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light
    }
  }
})
