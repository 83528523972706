<template>
  <div>
    <h2 class="mt-8">{{ $t('Files') }}</h2>
    <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
    <div class="files-list mt-6" v-else-if="files.length">
      <v-data-table
        :headers="headers"
        :items="filesView"
        :no-data-text="$t('No files exist')"
        sort-by="birthtime"
        sort-desc
        :footer-props="{
          'items-per-page-all-text': $t('All'),
          'items-per-page-text': $t('Rows per page'),
          'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
          'items-per-page-options': []
        }"
      >
        <template #[`item.name`]="{ item }">
          <span @click="openFile(item)">{{ item.name }}</span>
        </template>
        <template #[`item.size`]="{ item }">
          <span @click="openFile(item)">{{ Math.round(item.size / 1000) }}kb</span>
        </template>
        <template #[`item.birthtime`]="{ item }">
          <span>{{ getDate(item.birthtime) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon class="icon-with-hover" small @click="handleRestore(item)" v-if="userProfile.role === Role.admin && $route.name === 'SystemSetting'">fa-history</v-icon>
          <v-icon class="ml-3 icon-with-hover" small @click="downloadFile(item)" v-if="userProfile.role === Role.admin">fa-download</v-icon>
          <v-icon class="ml-3 icon-with-hover" small @click="openFile(item)" v-else>fa-external-link-alt</v-icon>
          <v-icon class="ml-3 files-list__item-remove icon-with-hover" small @click="deleteFile(item)">fa-trash</v-icon>
        </template>
      </v-data-table>
    </div>
    <div class="info-text mt-6" v-else-if="!loading" style="max-width: 400px;">
      <v-icon>fa-circle-info</v-icon>
      <p>
        {{ $t('There are no uploaded files') }}
      </p>
    </div>
    <h2 class="mt-10 mb-6">{{ $t('Upload new files') }}</h2>
    <vue-dropzone
      ref="VueDropzone"
      id="dropzone"
      :options="dropzoneOptions"
      @vdropzone-success-multiple="success"
      @vdropzone-thumbnail="thumbnail"
      @vdropzone-upload-progress="uploadProgress"
      useCustomSlot
    >
      <div class="dropzone-custom-content">
        <p class="ma-0 dropzone-custom-title">{{ $t('Upload your files here. Maximum file size is 5MB, only image files and pdf file types are allowed') }}</p>
      </div>
    </vue-dropzone>
    <v-row v-if="userProfile.role === Role.admin && $route.name === 'SystemSetting'">
      <v-col cols="12">
        <h2 class="mt-10 mb-2">{{ $t('Import and Export') }}</h2>
        <p>{{ $t('Important: Exporting is unavailable if a live auction is running') }}</p>
      </v-col>
      <v-col cols="4">
        <v-select
          class="input-field"
          hide-details="true"
          v-model="type"
          :items="typeItems"
          append-icon="fa-angle-down"
          :label="$t('Select item')"
        />
      </v-col>
      <v-col cols="8">
        <v-btn @click="handlerExport" class="primary-btn square-btn mt-3" :disabled="!type" :loading="exportLoading">
          <v-icon>fa-download</v-icon>
        </v-btn>
        <al-input-file-btn
          @fileChange="handleImport"
          acceptFile=".json,.sql"
          icon="fa-upload"
          btnClass="primary-btn square-btn mt-3 ml-3"
        />
      </v-col>
    </v-row>
    <confirmation-modal
      :title="$t('Do you really want to delete this file?')"
      @submit="submitRemove"
      @cancel="cancelRemove"
      ref="confirmationModal"
    />
    <confirmation-modal
      :title="$t('Do you really want to proceed with the import? The backend will be restarted during the process. Please make sure no users are connected and wait 1 minute before logging in again')"
      @submit="submitImport"
      @cancel="cancelImport"
      :loading="importLoading"
      ref="importConfirmationModal"
    />
    <confirmation-modal
      :title="$t('Do you really want to proceed with the rollback? The backend will be restarted during the process. Please make sure no users are connected and wait 1 minute before logging in again')"
      @submit="restoreFile"
      @cancel="cancelRestore"
      ref="restoreConfirmationModal"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'pinia'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import AlInputFileBtn from '@/components/form/input-file'
import useRootStore from '@/stores/rootStore';
import { getAuthToken } from '@/utils/token';
import dispatcher from '@/api/dispatch';
import { AlertFlavor, Role } from '@/api/enums';

export default {
  name: 'FilesManagement',
  components: {
    vueDropzone: vue2Dropzone,
    ConfirmationModal,
    AlInputFileBtn
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('Filename'),
          value: 'name',
          align: 'left',
        },
        {
          text: this.$t('Size'),
          value: 'size',
          align: 'left',
        },
        {
          text: this.$t('Created at'),
          value: 'birthtime',
          align: 'left',
        },
        {
          text: this.$t('Actions'),
          value: 'actions',
          align: 'left',
          sortable: false
        },
      ],
      type: '',
      typeItems: [
        {
          text: this.$t('Configuration'),
          value: 'config'
        },
        {
          text: this.$t('Database'),
          value: 'db'
        }
      ],
      exportLoading: false,
      importFile: null,
      start: 0,
      end: 0,
      page: 0,
      itemPerPage: 10,
      importLoading: false,
      mutableFiles: this.files
    }
  },
  computed: {
    Role() {
      return Role
    },
    ...mapState(useRootStore, ['userProfile']),
    filesView() {
      return [...this.mutableFiles].sort((a, b) => {
        const aTime = moment(a.birthtime)
        const bTime = moment(b.birthtime)
        return bTime - aTime
      })
    },
    dropzoneOptions() {
      return {
        url: `${process.env.VUE_APP_SERVER}/api/v2/users/${this.userId}/files`,
        maxFilesize: 5,
        headers: {
          Authorization: `Bearer ${getAuthToken()}`
        },
        paramName: function () {
          return 'files'
        },
        // includeStyling: false,
        // previewsContainer: false,
        previewTemplate: this.template(),
        thumbnailWidth: 150,
        uploadMultiple: true,
        parallelUploads: 5,
        maxFiles: 5,
        acceptedFiles: '.jpeg,.jpg,.png,.gif,.pdf',
        renameFile: this.renameFile
      }
    },
  },
  methods: {
    ...mapActions(useRootStore, ['logout', 'SET_TEMP_ALERT', 'SET_LOGIN_BUTTON_DISABLED']),
    renameFile(file) {
      const newFilename = file.name.replaceAll("ä", "ae").replaceAll("ö", "oe").replaceAll("ü", "ue").replaceAll("Ä", "Ae").replaceAll("Ö", "Oe").replaceAll("Ü", "Ue").replaceAll("ß", "ss");
      return newFilename;
    },
    thumbnail (file, dataUrl) {
      let j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove('dz-file-preview')
        ref = file.previewElement.querySelectorAll('[data-dz-thumbnail-bg]')
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        return setTimeout(((function() {
          return function() {
            return file.previewElement.classList.add('dz-image-preview')
          };
        })(this)), 1);
      }
    },
    template() {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress><span class="progress-text"></span></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark green-color-success"><i class="fa fa-check-circle"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
    },
    success(e) {
      this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('File uploaded successfully') })
      e.forEach(file => {
        if (this.mutableFiles.findIndex(el => el.name === file.upload.filename) >= 0) return
        const target = JSON.parse(file.xhr.response)
        const targetData = target.data.files.find(el => el.name === file.upload.filename)
        this.mutableFiles.push({
          mimetype: file.type,
          name: file.upload.filename,
          size: file.size,
          birthtime: targetData.birthtime
        })
      })
    },
    deleteFile(file) {
      this.selectedFile = file
      this.$refs.confirmationModal.openModal()
    },
    handleImport(file) {
      this.importFile = file
      this.$refs.importConfirmationModal.openModal()
    },
    handleRestore(file) {
      this.importFile = file
      this.$refs.restoreConfirmationModal.openModal()
    },
    async submitRemove() {
      try {
        if (this.userProfile.role === Role.admin) {
          await dispatcher.removeAdminFile(this.$route.name === 'SystemSetting' ? null : this.userId, this.selectedFile.name, this.$route.name === 'SystemSetting' ? 'backup' : '')
        } else {
          await dispatcher.removeFile(this.userId, this.selectedFile.name)
        }
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('File deleted successfully') })
        const idx = this.mutableFiles.findIndex(el => el.name === this.selectedFile.name)
        this.mutableFiles.splice(idx, 1)
        this.$refs.VueDropzone.removeAllFiles(this.selectedFile)
        this.cancelRemove()
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error deleting the file') })
        this.cancelRemove()
      }
    },
    cancelRemove() {
      this.$refs.confirmationModal.closeModal()
      this.selectedFile = null
    },
    cancelImport() {
      this.importFile = null
      this.$refs.importConfirmationModal.closeModal()
    },
    cancelRestore() {
      this.importFile = null
      this.$refs.restoreConfirmationModal.closeModal()
    },
    async openFile(file) {
      try {
        const result = await dispatcher.getFile(this.userId, file.name)
        window.open(URL.createObjectURL(result))
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error opening the file') })
      }
    },
    async downloadFile(file) {
      try {
        const result = await dispatcher.getAdminFile(this.$route.name === 'SystemSetting' ? null : this.userId, file.name, this.$route.name === 'SystemSetting' ? 'backup' : '')
        const a = document.createElement('a')
        a.href = URL.createObjectURL(result)
        a.setAttribute('download', file.name)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error downloading the file') })
      }
    },
    uploadProgress(file, progress) {
      if (file.previewElement) {
        const progressElement = file.previewElement.querySelector('[data-dz-uploadprogress]')
        progressElement.style.width = progress + '%'
        progressElement.querySelector('.progress-text').textContent = parseInt(progress) + '%'
      }
    },
    getDate(date) {
      if (!date) return
      const d = new Date(date);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang || 'de', { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}:${s <= 9 ? '0' + s : s}`
    },
    async handlerExport() {
      try {
        this.exportLoading = true
        const resp = await dispatcher.exportFiles(this.type)
        if (this.type === 'config') {
          const json = JSON.stringify(resp, null, 4);
          let a = document.createElement('a')
          a.href = `data:text/plain;charset=utf-8,${encodeURIComponent(json)}`
          a.download = `system-config-${moment().format('DDMMYYYY')}.json`
          a.click()
        } else {
          const textToSaveAsBlob = new Blob([resp], { type: 'application/sql' })
          let a = document.createElement('a')
          a.href = window.URL.createObjectURL(textToSaveAsBlob)
          a.download = `db-${moment().format('DDMMYYYY')}.sql`
          a.click()
        }
        this.exportLoading = false
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Export succeeded') })
      } catch (e) {
        this.exportLoading = false
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error during the export') })
      }
    },
    async submitImport() {
      try {
        this.importLoading = true
        const formData = new FormData()
        formData.append('file', this.importFile)
        const type = this.importFile.name.split('.').splice(-1)[0] === 'json' ? 'config' : 'db'
        await dispatcher.importAdminFiles(type, formData)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Import succeeded. You will be logged out, please wait a while before logging in again') })
        this.importLoading = false
        this.cancelImport()
        await this.logout()
        this.SET_LOGIN_BUTTON_DISABLED(true)
        setTimeout(() => {
          this.SET_LOGIN_BUTTON_DISABLED(false)
        }, 30000)
      } catch (e) {
        this.importLoading = false
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error during the import') })
      }
    },
    async restoreFile() {
      try {
        await dispatcher.restoreAdminFile(this.importFile.name)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Import succeeded. You will be logged out, please wait a while before logging in again') })
        this.cancelRestore()
        await this.logout()
        this.SET_LOGIN_BUTTON_DISABLED(true)
        setTimeout(() => {
          this.SET_LOGIN_BUTTON_DISABLED(false)
        }, 30000)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error during the restore') })
      }
    }
  },
  watch: {
    files(val) {
      this.mutableFiles = val;
    }
  }
}
</script>

<style lang="scss">
.progress-text {
  display: block;
  line-height: 16px;
  font-size: 16px;
  text-align: center;
  color: #262626;
}
</style>
