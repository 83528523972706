<template>
  <v-card class="auth-container-wrapper">
    <a v-if="(!isMobile && !isViewer) || (!isMobile && bannerUrl && !isViewer) || (isMobile && bannerUrl && mobileViewShowBanner)" :href="appSettings.url_auction_house" target="_blank">
      <v-img :src="bannerUrl" class="banner-img" contain />
    </a>
    <v-app-bar style="z-index: 2" class="main-header" elevation="0" color="white" absolute :key="appStoreLang">
      <a v-if="(!isMobile && showLogoOnDesktop) || (isMobile && showLogoOnMobile)" :href="appSettings.url_auction_house" target="_blank" class="logo">
        <v-img v-if="appSettings.url_logo_auction_house" :src="appSettings.url_logo_auction_house" contain width="100px" height="100px" />
        <v-img v-else :src="require('@/assets/logo.svg')" contain width="100px" height="100px" />
      </a>
      <div class="desktop-menu" v-if="!isTablet && !isViewer">
        <v-btn class="navigation-text" @click="openDashboard" :class="{ active: $route.name === 'dashboard' }">
          <span class="navigation-text__icon">
            <v-icon>fa-home</v-icon>
          </span>
          <span>{{ $t('menu_home') }}</span>
        </v-btn>
        <v-btn :to="{ name: 'catalogue' }" class="navigation-text" :class="{ active: $route.name === 'catalogue' }">
          <span class="navigation-text__icon">
            <v-icon>fa-book-open</v-icon>
          </span>
          <span>{{ $t('menu_catalogue') }}</span>
        </v-btn>
        <v-btn :to="{ name: 'wishlist' }" class="navigation-text" :class="{ active: $route.name === 'wishlist' }">
          <span class="navigation-text__icon">
            <v-icon style="font-weight: 400;">fa-heart</v-icon>
          </span>
          <span>{{ $t('My Wishlist') }}</span>
        </v-btn>
        <v-btn :to="{ name: 'bids' }" class="navigation-text" :class="{ active: $route.name === 'bids' }">
          <span class="navigation-text__icon">
            <v-icon>fa-gavel</v-icon>
          </span>
          <span>{{ $t('My Bids') }}</span>
        </v-btn>
        <v-btn :to="{ name: 'knockdowns' }" class="navigation-text" :class="{ active: $route.name === 'knockdowns' }">
          <span class="navigation-text__icon">
            <v-icon>fa-shopping-cart</v-icon>
          </span>
          <span>{{ $t('My Knockdowns') }}</span>
        </v-btn>

        <v-menu offset-y open-on-hover content-class="sub-menu" transition="slide-y-transition" v-if="!_.isEmpty(pastAuctions)">
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="navigation-text" :class="{ active: $route.name === 'pastAuctions' }">
              <span class="navigation-text__icon">
                <v-icon>fa-book</v-icon>
              </span>
              <span>{{ $t('Past Auctions') }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="auction in pastAuctions" :key="auction.id">
              <router-link :to="{ name: 'pastAuctions', params: { id: auction.code } }" class="navigation-icon mx-0">
                <span>{{ $t('Auction') }} {{ auction.code + " (" + moment(String(auction.start_at)).format('D.M.Y') +")" }}</span>
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-spacer />
      <v-text-field
        v-model="searchTerm" width="48px" class="mx-md-4 search search-field logged" solo
        hide-details="auto" append-icon="fa-search" height="38px" :label="$t('Search')"
        :placeholder="$t('Search')" @click:append="searchData" @keyup.enter="searchData" v-if="!isViewer"
      />
      <theme-switch class="mr-2 mt-1" v-if="!isViewer" />
      <div class="user-lang-switcher" v-if="!isViewer">
        <lang-siwtcher :key="appStoreLang" z-index="1010" theme="light" />
      </div>
      <v-btn class="navigation-icon search-icon logged" width="48px" height="48px" icon @click="search" v-if="!isViewer">
        <v-icon>fa-search</v-icon>
      </v-btn>
      <search-modal ref="searchModal" />
      <button type="button" class="burger" @click="mobileMenu = !mobileMenu" :class="{ active: mobileMenu }">
        <span />
      </button>
      <div class="menu" :class="{ active: mobileMenu }">
        <div class="menu__overlay" />
        <div class="menu__bg" />
        <div class="menu__navigation" v-if="!isViewer">
          <v-btn @click="openDashboard" class="navigation-icon" width="48px" height="48px" icon v-if="isTablet" :class="{ active: $route.name === 'dashboard' }">
            <div class="v-icon">
              <v-icon>fa-home</v-icon>
            </div>
            <span>{{ $t('menu_home') }}</span>
          </v-btn>
          <v-btn @click="openProductCatalogue" class="navigation-icon" width="48px" height="48px" icon v-if="isTablet" :class="{ active: $route.name === 'catalogue' }">
            <div class="v-icon">
              <v-icon>fa-book-open</v-icon>
            </div>
            <span>{{ $t('menu_catalogue') }}</span>
          </v-btn>
          <v-btn @click="openWishList" class="navigation-icon" width="48px" height="48px" icon v-if="isTablet" :class="{ active: $route.name === 'wishlist' }">
            <v-icon>fa-heart</v-icon>
            <span>{{ $t('My Wishlist') }}</span>
          </v-btn>
          <v-btn @click="openBidsModal" class="navigation-icon" width="48px" height="48px" icon v-if="isTablet" :class="{ active: $route.name === 'bids' }">
            <v-icon>fa-gavel</v-icon>
            <span>{{ $t('My Bids') }}</span>
          </v-btn>
          <v-btn @click="openKnockdownsModal" class="navigation-icon" width="48px" height="48px" icon v-if="isTablet" :class="{ active: $route.name === 'knockdowns' }">
            <div class="v-icon">
              <v-icon>fa-shopping-cart</v-icon>
            </div>
            <span>{{ $t('My Knockdowns') }}</span>
          </v-btn>
          <v-list-group content-class="sub-menu" v-if="isTablet && !_.isEmpty(pastAuctions)">
            <template #activator>
              <v-btn
                class="navigation-icon" width="48px" height="48px" icon
                :class="{ active: $route.name === 'pastAuctions' }"
              >
                <div class="v-icon">
                  <v-icon>fa-book</v-icon>
                </div>
                <span>{{ $t('Past Auctions') }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="auction in pastAuctions" :key="auction.id">
                <v-btn class="navigation-icon" icon @click="$router.push({ name: 'pastAuctions', params: { id: auction.code } }); mobileMenu = false">
                  <div class="v-icon" style="width:0;" />
                  <span class="ml-0">{{ $t('Auction') }} {{ auction.code + " (" + moment(String(auction.start_at)).format('D.M.Y') +")" }}</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-list-group>
          <v-menu offset-y open-on-hover content-class="sub-menu" :disabled="isTablet" transition="slide-y-transition">
            <template #activator="{ on, attrs }">
              <v-btn class="navigation-icon" icon v-bind="attrs" v-on="on" :class="{ active: $route.name === 'profile' }">
                <div class="v-icon">
                  <v-icon>fa-user</v-icon>
                </div>
                <p class="name my-0 text-capitalize" :class="{ 'ml-0': !isTablet }">{{ userProfile.first_name }}</p>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <router-link :to="{ name: 'profile', query: { tab: 'personalData' } }" class="navigation-icon mx-0">
                  <span>{{ $t('Profile') }}</span>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'profile', query: { tab: 'bidData' } }" class="navigation-icon mx-0">
                  <span>{{ $t('Bidding data') }}</span>
                </router-link>
              </v-list-item>
              <v-list-item>
                <router-link :to="{ name: 'profile', query: { tab: 'password' } }" class="navigation-icon mx-0">
                  <span>{{ $t('Change password') }}</span>
                </router-link>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-row v-if="isTablet" class="mr-3 ml-6 pl-6 flex-column">
            <v-btn class="navigation-icon" icon @click="$router.push({ name: 'profile', query: { tab: 'personalData' } }); mobileMenu = false">
              <div class="v-icon" style="width:0;" />
              <span class="ml-0">{{ $t('Profile') }}</span>
            </v-btn>
            <v-btn class="navigation-icon" icon @click="$router.push({ name: 'profile', query: { tab: 'bidData' } }); mobileMenu = false">
              <div class="v-icon" style="width:0;" />
              <span class="ml-0">{{ $t('Bidding data') }}</span>
            </v-btn>
            <v-btn class="navigation-icon" icon @click="$router.push({ name: 'profile', query: { tab: 'password' } }); mobileMenu = false">
              <div class="v-icon" style="width:0;" />
              <span class="ml-0">{{ $t('Change password') }}</span>
            </v-btn>
          </v-row>

          <v-btn @click="openInquiryModal" class="navigation-icon" width="48px" height="48px" icon>
            <v-icon style="font-weight: 400;">fa-question-circle</v-icon>
            <span>{{ $t('Questions') }}</span>
          </v-btn>
          <v-btn class="navigation-icon logout-icon" width="48px" height="48px" icon @click="logoutHandler">
            <div class="v-icon">
              <v-icon>fa-sign-out-alt</v-icon>
            </div>
            <span>{{ $t('Logout') }}</span>
          </v-btn>
        </div>
      </div>
    </v-app-bar>
    <user-general-inquiry-modal
      ref="generalInquiryModal"
    />
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import UserGeneralInquiryModal from '@/components/modals/user-general-inquiry-modal'
import { localFeatures } from '@/utils/constants';
import ThemeSwitch from '@/components/header/theme-switch'
import SearchModal from '@/components/modals/search-modal'
import dispatcher from '@/api/dispatch';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from "@/stores/auctionStore";
import { AlertFlavor } from '@/api/enums';
import { AuctionFlavor, AuctionStatusType, Role } from '@/api/enums';

export default {
  name: 'UserNav',
  props: {
    userId: Number,
  },
  components: {
    ThemeSwitch,
    UserGeneralInquiryModal,
    SearchModal
  },
  data () {
    return {
      mobileMenu: false,
      dialog: false,
      loading: false,
      question: null,
      isTablet: window.innerWidth <= 1300,
      searchTerm: '',
      pastAuctions: [],
      bannerUrl: localFeatures.urlBannerAuctionhouse,
      mobileViewShowBanner: localFeatures.mobileViewShowBanner,
      showLogoOnDesktop: localFeatures.showLogoOnDesktop,
      showLogoOnMobile: localFeatures.showLogoOnMobile
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appSettings', 'appStoreLang', 'appLoading', 'isMobile']),
    ...mapState(useAuctionStore, ['currentAuction']),
    buttonStatus() {
      return !this.question || this.disableButton
    },
    isViewer() {
      return this.userProfile.role === Role.viewer
    }
  },
  async created() {
    await this.getPastAuctions();
    window.addEventListener('resize', this.resizeHandler)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_LOGOUT_CLICKED', 'logout']),
    resizeHandler() {
      this.isTablet = window.innerWidth <= 1300
    },
    openDashboard() {
      if (this.$route.name === 'dashboard') {
        this.UPDATE_GLOBAL_STATE({ key: 'showAuctionOverview', value: true })
      } else {
        this.$router.push({ name: 'dashboard' })
      }
      this.mobileMenu = false
    },
    openProfileModal() {
      this.$router.push({ name: 'profile' })
      this.mobileMenu = false
    },
    openKnockdownsModal() {
      this.$router.push({ name: 'knockdowns' })
      this.mobileMenu = false
    },
    openBidsModal() {
      this.$router.push({ name: 'bids' })
      this.mobileMenu = false
    },
    openTimedBidsModal() {
      this.$router.push({ name: 'timedBids' })
      this.mobileMenu = false
    },
    openProductCatalogue() {
      this.$router.push({ name: 'catalogue' })
      this.mobileMenu = false
    },
    openWishList() {
      this.$router.push({ name: 'wishlist' })
      this.mobileMenu = false
    },
    openHome() {
      this.$router.push({ name: 'dashboard' })
      this.mobileMenu = false
    },
    openInquiryModal() {
      this.$refs.generalInquiryModal.openModal()
      this.mobileMenu = false
    },
    closeModal() {
      this.$refs.generalInquiryModal.closeModal()
    },
    search() {
      this.$refs.searchModal.openModal()
    },
    searchData() {
      let searchTerm = this.searchTerm.trim()
      //searchTerm = searchTerm.replace(" ", "%20")
      if (searchTerm !== '' && (!this.$route.query.q || this.$route.query.q !== searchTerm)) {
        this.$router.push({ name: 'search', query: { q: searchTerm } });
      }
      this.searchTerm = '';
    },
    logoutHandler() {
      this.SET_LOGOUT_CLICKED(true);
      this.logout()
    },
    async getPastAuctions() {
      try {
        const auctions = await dispatcher.getAllAuctions()
        this.pastAuctions = auctions.filter(el => {
          return moment(el.active_until).diff(this.moment(), 'seconds') > 0 && el.type === AuctionFlavor.live && el.status === AuctionStatusType.completed
        })
        this.pastAuctions.sort((a, b) => { return b.id - a.id });
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error getting the past auctions. Please try again later') })
        this.error = true;
      }
    },
  },
  watch: {
    currentAuction: {
      deep: true,
      handler() {
        if (this.currentAuction.status === undefined) {
          this.getPastAuctions();
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.logo {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}
.logout-icon {
  @media (max-width: 968px) {
    position: relative;
  }
}
</style>
