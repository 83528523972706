<template>
  <div v-if="appLoading">
    <!-- <FlashPhoner /> -->
    <div class="auction-container admin" v-if="currentAuction && currentAuction.id && currentAuction.type === AuctionFlavor.live">
      <v-row no-gutters class="auction-wrapper flex-nowrap">
        <articles-list
          :isArticleSoldOut="isArticleSoldOut"
          :loading="articlesLoading"
          :articleIsStarted="articleIsStarted"
          :cachedCurrentArticle="cachedCurrentArticle"
          @syncData="syncData"
          @articleJump="articleJump"
        />
        <v-col style="height: 100%" class="article-wrapper pt-16" v-if="!noOngoingArticle">
          <v-row class="title-wrapper" no-gutters>
            <v-col lg="12" md="12" sm="12" xs="12">
              <div class="title">{{ ongoingArticle.name }}</div>
            </v-col>
            <v-row class="justify-start my-0">
              <v-col class="price-wrapper no-gutters">
                <span class="light-title">{{ $t('Article') }}</span>&nbsp;
                <span class="price-value">{{ getArticleNumber }}</span>
              </v-col>
              <br v-if="ongoingArticle.estimated_price">
              <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.limit">
                <span class="light-title">{{ $t('Limit') }}</span>&nbsp;
                <span class="price-value">{{ currency }}{{ euNumbers(ongoingArticle.limit) }}</span>
              </v-col>
              <br v-if="ongoingArticle.limit">
              <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.estimated_price">
                <span class="light-title">{{ $t('Estimated price') }}</span>&nbsp;
                <span class="price-value">{{ currency }}{{ ongoingArticle.estimated_price }}</span>
              </v-col>
            </v-row>
          </v-row>

          <v-row>
            <v-col cols="12" lg="8" md="8" sm="8" xs="8" class="left-side">
              <v-row justify="space-between" class="ma-0">
                <v-btn key="isPreviousArticle" :disabled="!isNextArticle && isArticleSoldOut || showExternalKnockDownPopup || currentAuction.status === AuctionStatusType.paused" v-if="isPreviousArticle" class="btn primary-btn previous-item-btn" @click="jumpToPreviousArticle()"><span>{{ $t('Previous item') }}</span></v-btn>
                <div class="next-item-btn spacer-button" v-else />
                <v-btn key="isReOffer" :disabled="!isNextArticle && isArticleSoldOut || showExternalKnockDownPopup || currentAuction.status === AuctionStatusType.paused" v-if="roomBidderSubmitted || isArticleSoldOut" class="btn primary-btn next-item-btn" @click="reOffer()"><span>{{ $t('Re-offer') }}</span></v-btn>
                <v-btn key="isNextArticle" :disabled="showExternalKnockDownPopup || currentAuction.status === AuctionStatusType.paused" v-if="isNextArticle" class="btn primary-btn next-item-btn" @click="jumpToNextArticle()"><span>{{ $t('Next item') }}</span></v-btn>
                <div class="next-item-btn spacer-button" v-else />
              </v-row>

              <v-card class="operation-box" flat>
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="6" xs="6">
                    <div class="p-l-r-15">
                      <p class="normal-text ma-0 mb-1">{{ $t('Current highest bid') }}</p>
                      <p class="bold-500 bid-value" :class="{ redText: !btrDisabled }">
                        {{ currency }}{{ euNumbers(currentHighestBid.value) }}
                        <span v-if="!btrDisabled"> Live (#{{ currentHighestBid.bidder_number }})</span>
                      </p>
                      <p v-if="isArticleSoldOut" class="bold-500 sold-out">
                        <span>{{ $t('This item is sold out') }}</span>
                      </p>
                      <p v-if="!_.isEmpty(ongoingArticle) && currentAuctionArticles.length && !isNextArticle" class="bold-500 sold-out-last">
                        <span>{{ $t('Last article sold out, please stop the auction now') }}</span>
                      </p>
                    </div>
                  </v-col>
                  <v-col coDie Auktion wurde angehaltenls="5" offset="1" class="pb-0">
                    <v-row class="mr-4 mt-1">
                      <form
                        class="ask-block" autocomplete="off" @submit.prevent="doAskBid"
                        :class="{
                          disabled: currentAuction.status !== AuctionStatusType.started,
                          changed: (ongoingArticle.last_ask_price !== nextBidAmount && nextBidAmount > 0)
                        }"
                      >
                        <v-btn
                          class="btn primary-btn save-ask-btn"
                          :disabled="isArticleSoldOut || currentAuction.status !== AuctionStatusType.started"
                          type="submit"
                        >
                          {{ ongoingArticle.last_ask_price > 0 ? $t('Save') : $t('Start') }}
                        </v-btn>
                        <label for="ask-value" class="ask-label">{{ $t('Ask') }}</label>
                        <input
                          :value="localNextBid"
                          @keyup="updateAskPrice"
                          @focus="focusHandler"
                          @keydown="inputAskPriceHandler"
                          @blur="enableAskFieldArrows = false"
                          id="ask-value"
                          type="text"
                          ref="askValue"
                          :disabled="isArticleSoldOut"
                          :placeholder="$t('Enter value')"
                        >
                      </form>
                    </v-row>
                    <v-row class="mt-1 mr-4" style="justify-content: flex-end;">
                      <v-col cols="6" class="pr-1 pl-0 pt-0">
                        <span class="room-bid-loading">
                          <!-- <v-progress-circular v-if="bidSubmit" class="loading-icon" indeterminate /> -->
                          <v-btn
                            class="btn primary-btn hall-bid-btn action-btn"
                            :disabled="(isArticleSoldOut || currentAuction.status !== AuctionStatusType.started || isButtonDisabled)"
                            @click="processBid(false)"
                          >
                            <span>{{ $t('Room Bid') }}</span>
                          </v-btn>
                        </span>
                      </v-col>
                      <v-col cols="6" class="pl-1 pr-0 pt-0">
                        <v-btn
                          class="btn primary-btn send-bid-warning-btn action-btn"
                          :disabled="currentAuction.status !== AuctionStatusType.started || isButtonDisabled || isArticleSoldOut || _.isEmpty(this.currentAuction.highestBid) || bidWarningRefresh"
                          @click="sendBidWarning()"
                        >
                          <span v-if="!bidWarningRefresh">{{ $t('Send bid warning') }}</span>
                          <span v-if="bidWarningRefresh"><span>{{ $t('In progress...') }}</span><span class="circle-number">{{ bidWarningNumber }}</span></span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="currentAuction">
                  <v-col class="pt-0" cols="12" lg="12" md="12">
                    <v-row class="align-center auction-buttons">
                      <v-col class="align-center pt-0" cols="4">
                        <v-checkbox
                          :disabled="isArticleSoldOut || currentAuction.status !== AuctionStatusType.started || isButtonDisabled"
                          v-if="ongoingArticle"
                          class="checkbox-wrapper"
                          color="#1F37B6"
                          :input-value="isConditionalActive"
                          @click="onUpdateConditional()"
                        >
                          <template #label>
                            <div class="condition-text">
                              <span v-bind:class="{'disabled-text': isArticleSoldOut || currentAuction.status !== AuctionStatusType.started || isButtonDisabled}">{{ $t('Conditional') }}</span>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col class="text-right py-0" cols="3">
                        <v-btn
                          class="btn primary-btn hall-bid-btn action-btn auto-width"
                          :disabled="isArticleSoldOut || currentAuction.status !== AuctionStatusType.started || isButtonDisabled"
                          @click="undoBid"
                        >
                          <span>{{ $t('Undo') }}</span>
                        </v-btn>
                      </v-col>
                      <v-col cols="5" class="pl-0 py-0">
                        <v-row class="mx-0">
                          <v-col cols="6" class="pl-0 pr-1 py-0">
                            <v-btn
                              :disabled="btrDisabled || isArticleSoldOut || currentAuction.status !== AuctionStatusType.started || isButtonDisabled"
                              class="btn primary-btn hall-bid-btn action-btn"
                              @click="processBid(true)"
                            >
                              <span>{{ $t('BTR') }}</span>
                            </v-btn>
                          </v-col>
                          <v-col cols="6" class="text-right pl-1 pr-0 py-0">
                            <v-tooltip
                              v-model="showExternalKnockDownPopup"
                              class="hall-bid"
                              content-class="hall-bid-content"
                              bottom
                              right
                              internal-activator
                              max-width="400"
                              :open-on-hover="false"
                              :min-width="operationBoxWidth"
                            >
                              <template #activator="{ on, attrs }"> <!-- eslint-disable-line -->
                                <v-btn
                                  :disabled="isArticleSoldOut || currentAuction.status !== AuctionStatusType.started || isButtonDisabled || bidHistory.length <= 0"
                                  class="btn primary-btn knock-down-btn action-btn"
                                  type="button"
                                  @click="doKnockDown"
                                >
                                  <span>{{ $t('Knockdown') }}</span>
                                </v-btn>
                              </template>
                              <div class="input-group">
                                <label>
                                  <input
                                    v-model.number="externalBidderNumber"
                                    @keyup.enter="doExternalKnockDown"
                                    ref="refExternalBidderNumber"
                                    max="99999"
                                    @keydown="inputExternalBiddernumberHandler"
                                    @input="bidderNumberHandler"
                                    type="number" :placeholder="$t('External bidder number')"
                                  >
                                </label>
                                <v-btn class="btn primary-btn submit-btn" @click="doExternalKnockDown"><span>{{ $t('Save') }}</span></v-btn>
                              </div>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-row class="pt-8 mx-0 mb-2">
                <v-col class="image-slider-wrapper" cols="4">
                  <VueSlickCarousel lazyLoad="ondemand" :key="articleGallery.length" v-if="articleGallery && articleGallery.length > 0" :arrows="true" :dots="false" class="slider-container">
                    <template #prevArrow>
                      <div class="custom-arrow">
                        <v-icon class="arrow-left">fa-angle-left</v-icon>
                      </div>
                    </template>
                    <template #nextArrow>
                      <div class="custom-arrow">
                        <v-icon class="arrow-right">fa-angle-right</v-icon>
                      </div>
                    </template>
                    <div class="image-item" v-for="itm in articleGallery" :key="itm.articleNumber">
                      <v-img class="article-image" :contain="true" :src="itm.image" height="25vh" width="400px" />
                    </div>
                  </VueSlickCarousel>
                </v-col>
                <v-col cols="8">
                  <div class="description overflow-auto" :style="{ maxHeight: descriptionMaxHeight }">
                    <span v-if="useHtml" class="useHtmlData" v-html="ongoingArticle.description"></span>
                    <span v-else>{{ ongoingArticle.description }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="4" xs="4" class="right-side">
              <v-row class="first-row mt-0">
                <span class="title">{{ $t('Bid history') }}</span>
              </v-row>
              <v-data-table
                class="bid-history"
                :headers="dataTable.headers"
                :items="localBidHistory"
                hide-default-footer
                :no-data-text="$t('No data available')"
                :options="options"
                :style="{ maxHeight: bidHistoryMaxHeight }"
              >
                <template #[`item.created_at`]="item">
                  <span :class="item.item.type">{{ moment(new Date(item.item.created_at)).format('HH:mm:ss') }}</span>
                </template>
                <template #[`item.type`]="item">
                  <span :class="item.item.type">{{ $t(item.item.type) }}</span>
                </template>
                <template #[`item.bid`]="item">
                  <span :class="item.item.type">{{ currency }}{{ euNumbers(item.item.bid) }}</span>
                </template>
                <template #[`item.bidder_number`]="item">
                  <span :class="item.item.type">{{ item.item.bidder_number }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-overlay class="custom-back-drop status-modal" v-if="stopLoadingModal">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <v-icon class="warning-icon">fa-triangle-exclamation</v-icon>
              <br>
              <span class="loading-message">{{ getStopLoadingMessage() }}</span>
              <div class="button-list">
                <v-btn class="btn primary-btn yes-stop-btn" @click="stopAuction()"><span>{{ $t('Yes') }}</span></v-btn>
                <v-btn class="btn primary-btn cancel-btn" @click="CHANGE_STATE({key: 'stopLoadingModal', value: false})"><span>{{ $t('No') }}</span></v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-overlay>

      <v-overlay class="custom-back-drop no-ongoing-article-modal" v-if="noOngoingArticle && !articleJumpRequest">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <v-progress-circular
                class="loading-icon"
                indeterminate
                color="#555"
              />
              <br>
              <span class="loading-message">{{ $t('No ongoing article found in the live auction') }}</span>
            </div>
          </div>
        </div>
      </v-overlay>

      <!--      <articles-overview @changePanelState="state => sliderPanelActive = state" :articlesLength="articlesLength"/>-->
    </div>
    <div class="auction-container settings auctions" v-else>
      <div class="bid-step">
        <div class="section-title text-center"><span>{{ $t('No auction is currently started.') }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import SocketClient from '@/utils/socket'
import _, { isEmpty } from 'lodash'
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { mapActions, mapState } from 'pinia'
import ArticlesList from './admin-articles-list-desktop'
import tinykeys from '@/utils/tiny-keys'
import dispatcher from '@/api/dispatch';
import { localFeatures } from '@/utils/constants';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { euNumbers } from '@/services/i18n';
import { AlertFlavor, AuctionFlavor, AuctionStatusType, BidType, SocketNamespace } from '@/api/enums';

export default {
  name: 'AuctionAdmin',
  components: {
    VueSlickCarousel,
    ArticlesList
  },
  async created () {
    // setInterval(() => {
    //   caches.keys().then((keyList) => {
    //     console.log(keyList)
    //     Promise.all(keyList.map((key) => caches.delete(key)))
    //   })
    //   console.log('cache cleared')
    // }, 10000)
    this.localNextBid = this.nextBidAmount
    try {
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: false,
      })
      let resAuction = await this.getCurrentActiveAuction()
      if (resAuction) {
        this.galleryImagesLoadedId = this.currentAuction.ongoingArticle && this.currentAuction.ongoingArticle.id
        if (this.currentAuction && this.currentAuction.started_by_user_id !== this.userProfile.id) this.$router.push({ name: 'Auctions' })
      }

      this.setUpSocket()

      // Listener
      /* if (this.socket.customConnectionStatus !== SocketConnectionStatus.init) {
        console.log('added socket listeners (inside the auction)')
        this.socket.removeAllListeners()
        this.setUpSocket()
        this.socket.connect()
      } else {
        console.log('socket connected (inside the auction)')
        this.setUpSocket()
        this.socket.connect()
      } */

      // this.hotkeys = this.appLocalization.hotkeys

      this.removeListener = tinykeys(window, {
        '*': () => {
          if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
          this.undoBid()
        },
        // +
        '+': () => {
          if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
          this.sendBidWarning()
        },
        NumpadAdd: () => {
          if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
          this.sendBidWarning()
        },
        // /
        '/': () => {
          if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
          this.processBid(true)
        },
        '%': () => {
          if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
          this.processBid(true)
        },
        Enter: async () => {
          if (this.jumpLoadingModal || this.reauctionLoadingModal || this.currentAuction.status !== AuctionStatusType.started) return
          if (this.isFocus || (!this.showExternalKnockDownPopup && this.isButtonDisabled && !this.isArticleSoldOut)) {
            await this.doAskBid()
          } else if (!this.showExternalKnockDownPopup && !this.isArticleSoldOut && !this.bidSubmit) {
            await this.processBid(false)
          } else if (!this.showExternalKnockDownPopup && this.isArticleSoldOut) {
            this.jumpToNextArticle()
          }
        },
        '-': () => {
          if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started || this.isButtonDisabled || this.bidHistory.length <= 0) return
          this.doKnockDown()
        },
        ArrowLeft: () => {
          if (this.showExternalKnockDownPopup || this.enableAskFieldArrows) return
          !this.sliderPanelActive && this.jumpToPreviousArticle()
        },
        ArrowRight: () => {
          if (this.showExternalKnockDownPopup || this.enableAskFieldArrows) return
          !this.sliderPanelActive && this.jumpToNextArticle()
        },
        ArrowUp: () => {
          if (this.showExternalKnockDownPopup || this.isFocus) return
          this.focusHandler()
        }
      })

      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })
    } catch (e) {
      this.UPDATE_GLOBAL_STATE([
        {
          key: 'error',
          value: true,
        },
        {
          key: 'errorMessage',
          value: e.message,
        },
      ])
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })
    }
    if (this.currentAuction && this.currentAuction.id) {
      if (this.currentAuction.type === AuctionFlavor.live) {
        this.articlesLoading = true
        await this.getAuctionArticles({ auctionId: this.currentAuction.id })
        this.articlesLength = this.currentAuctionArticles.length
        this.articlesLoading = false
      }
    }

    const header = document.getElementsByClassName('app-header')[0]
    const wrapper = document.getElementsByClassName('title-wrapper')[0]
    const operationBox = document.getElementsByClassName('operation-box')[0]
    const imageSlider = document.getElementsByClassName('image-slider-wrapper')[0]
    const marginsTotal = 194
    if (header && wrapper && operationBox) {
      let height = window.innerHeight - header.clientHeight - wrapper.clientHeight - marginsTotal + imageSlider.clientHeight
      console.log(window.innerHeight, header.clientHeight, wrapper.clientHeight, marginsTotal, height, imageSlider.clientHeight)
      if (height < 0) height = 0
      this.bidHistoryMaxHeight = `${operationBox.clientHeight + imageSlider.clientHeight + 28}px`
      this.descriptionMaxHeight = `${height - operationBox.clientHeight}px`
    }
  },
  beforeDestroy () {
    this.removeListener();
  },
  data: function () {
    return {
      showExternalKnockDownPopup: false,
      toggleList: false,
      currentMobilePage: 1,
      showMobileArticleModal: false,
      articleGallery: [],
      galleryReady: false,
      dataTable: {
        headers: [
          {
            text: this.$t('Time'),
            align: 'start',
            sortable: false,
            value: 'created_at',
          },
          {
            text: this.$t('Type'),
            value: 'type',
            sortable: false,
            class: 'bid-type'
          },
          {
            text: this.$t('Bid'),
            value: 'bid',
            sortable: false,
          },
          {
            text: this.$t('Bidder number'),
            value: 'bidder_number',
            sortable: false,
            align: 'end',
          },
        ],
      },
      bidWarningRefresh: false,
      bidWarningNumber: 5,
      askPrice: null,
      externalBidderNumber: null,
      loading: false,
      operationBoxWidth: 475,
      forcedAskedPrice: null,
      modelAskPrice: 0,
      galleryImagesLoadedId: null,
      isArticleSoldOut: false,
      cachedCurrentArticle: null,
      localBidHistory: [],
      isConditionalActive: 0,
      disableAutoConditional: false,
      storeExternalResponse: null,
      bidSubmit: false,
      options: {
        itemsPerPage: 100
      },
      sliderPanelActive: false,
      roomBidderSubmitted: false,
      bidHistoryMaxHeight: '535px',
      descriptionMaxHeight: '1000px',
      localNextBid: null,
      isFocus: false,
      enableAskFieldArrows: false,
      articlesLength: 0,
      articlesLoading: false,
      articleIsStarted: false,
      articleJumpRequest: false,
      removeListener: null,
      useHtml: localFeatures.useHtml,
      lastSubmittedBid: null,
    }
  },
  watch: {
    async reOffered (val) {
      if (val) {
        this.isArticleSoldOut = false
        if (this.appSettings.autostart_articles && this.currentAuction.status === AuctionStatusType.started) await this.doAskBid()
        this.CHANGE_STATE({
          key: 'reOffered',
          value: false,
        })
      }
    },
    async currentAuction() {
      if (this.currentAuction && this.currentAuction.ongoingArticle && this.currentAuction.code && this.currentAuction.type === AuctionFlavor.live) {
        this.askPrice = this.currentAuction.ongoingArticle.last_ask_price > 0 ? this.currentAuction.ongoingArticle.last_ask_price : this.currentAuction.ongoingArticle.limit
      }
    },
    async ongoingArticle() {
      // this runs twice if jumped from an article to another one or re-offered
      // If commission bids are received, the first currentHighestBid.type is always 'null' and the second one is 'commission'
      this.articleIsStarted = this.ongoingArticle && this.ongoingArticle.last_ask_price > 0
      if (this.ongoingArticle.id && this.ongoingArticle.id !== this.cachedCurrentArticle) {
        this.localNextBid = this.nextBidAmount
        this.cachedCurrentArticle = this.ongoingArticle.id
        this.disableAutoConditional = false
        this.articleGallery = this.ongoingArticle.images
        this.galleryImagesLoadedId = this.currentAuction.ongoingArticle.id
        this.isArticleSoldOut = this.ongoingArticle.status === 0
        this.forcedAskedPrice = null
        if (this.appSettings.autostart_articles && this.currentAuction.status === AuctionStatusType.started) await this.doAskBid()
      }
    },
    nextBidAmount(val) {
      if (!this.isFocus) this.localNextBid = val
      if (!this.disableAutoConditional) { this.isConditionalActive = this.currentHighestBid.value < this.ongoingArticle.limit }
    },
    bidHistory() {
      // const arr = _.clone(this.bidHistory)
      // arr.map(el => { el.bid += this.currency })
      // this.localBidHistory = [...arr]
      this.localBidHistory = this.bidHistory
    },
    sendMessageModal(val) {
      if (val) {
        this.removeListener()
      } else {
        this.removeListener = tinykeys(window, {
          '*': () => {
            if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
            this.undoBid()
          },
          // +
          '+': () => {
            if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
            this.sendBidWarning()
          },
          NumpadAdd: () => {
            if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
            this.sendBidWarning()
          },
          // /
          '/': () => {
            if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
            this.processBid(true)
          },
          '%': () => {
            if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started) return
            this.processBid(true)
          },
          Enter: async () => {
            if (this.jumpLoadingModal || this.reauctionLoadingModal || this.currentAuction.status !== AuctionStatusType.started) return
            if (this.isFocus || (!this.showExternalKnockDownPopup && this.isButtonDisabled && !this.isArticleSoldOut)) {
              this.doAskBid()
            } else if (!this.showExternalKnockDownPopup && !this.isArticleSoldOut) {
              await this.processBid(false)
            } else if (!this.showExternalKnockDownPopup && this.isArticleSoldOut) {
              this.jumpToNextArticle()
            }
          },
          '-': () => {
            if (this.showExternalKnockDownPopup || this.isArticleSoldOut || this.currentAuction.status !== AuctionStatusType.started || this.isButtonDisabled || this.bidHistory.length <= 0) return
            this.doKnockDown()
          },
          ArrowLeft: () => {
            if (this.showExternalKnockDownPopup || this.enableAskFieldArrows) return
            !this.sliderPanelActive && this.jumpToPreviousArticle()
          },
          ArrowRight: () => {
            if (this.showExternalKnockDownPopup || this.enableAskFieldArrows) return
            !this.sliderPanelActive && this.jumpToNextArticle()
          },
          ArrowUp: () => {
            if (this.showExternalKnockDownPopup || this.isFocus) return
            this.focusHandler()
          }
        })
      }
    },
    async isOffline(newVal, oldVal) {
      if (!oldVal && newVal && !this.isLogoutClicked) {
        //await this.doAction({ type: 'pause', auctionId: this.currentAuction.id })
      } else {
        this.$forceUpdate()
        await this.getCurrentActiveAuction()
      }
      //this.$forceUpdate()
    }
  },
  computed: {
    AuctionStatusType() {
      return AuctionStatusType
    },
    AuctionFlavor() {
      return AuctionFlavor
    },
    ...mapState(useAuctionStore, ['currentAuction', 'stopLoadingModal', 'jumpLoadingModal', 'reauctionLoadingModal', 'currentAuctionArticles', 'reOffered', 'ongoingArticle', 'currentHighestBid', 'nextBidAmount', 'bidHistory']),
    ...mapState(useRootStore, ['appLoading', 'appLocalization', 'appSettings', 'appStoreLang', 'appFeatures', 'userProfile', 'sendMessageModal', 'disconnectedTime', 'isOffline', 'isLogoutClicked', 'socket', 'socketConnectionStatus']),
    noOngoingArticle() {
      return !_.isEmpty(this.currentAuction) && _.isEmpty(this.ongoingArticle)
    },
    currency () {
      return this.appLocalization.currency
    },
    getArticleNumber() {
      return (this.ongoingArticle.number_optional && `${this.ongoingArticle.number_optional}(${this.ongoingArticle.number})`) || `#${_.padStart(this.ongoingArticle.number, 4, '0')}`
    },
    isButtonDisabled() {
      const { ongoingArticle } = this.currentAuction
      if (!ongoingArticle) {
        return true
      } else {
        // the last bid is a commission bid, don't lock the buttons because otherwise if it is the first bid, no room bids can be issued
        if (this.currentAuction.highestBid.type === BidType.commission) return false
        // else
        return ongoingArticle.last_ask_price === 0 || ongoingArticle.status === '0'
      }
    },
    btrDisabled() {
      return this.currentAuction.highestBid.type !== BidType.live
    },
    minAskPrice() {
      const { ongoingArticle, highestBid, bidStep, default_bid_step: defaultBidStep } = this.currentAuction;
      if (_.isEmpty(ongoingArticle) || ongoingArticle.last_ask_price === 0) {
        return ongoingArticle.limit
      } else {
        return (!highestBid || (highestBid && highestBid.value === 0)) ? ongoingArticle.limit : ((highestBid && highestBid.value) || 0) + ((bidStep && bidStep.bid_step) || defaultBidStep)
      }
    },
    isNextArticle () {
      if (!this.currentAuction.ongoingArticle) return
      const { ongoingArticle } = this.currentAuction
      let index = _.findIndex(this.currentAuctionArticles, ['id', ongoingArticle.id]);
      // Go to the next article
      return index + 1 < this.currentAuctionArticles.length
    },
    isPreviousArticle () {
      if (!this.currentAuction.ongoingArticle) return
      const { ongoingArticle } = this.currentAuction
      let index = _.findIndex(this.currentAuctionArticles, ['id', ongoingArticle.id]);
      return index > 0
    },
    loadingMessage() {
      return {
        stop: this.$t('Do you really want to stop the active auction?'),
        jump: this.$t('Do you really want to jump to another article?'),
        reauction: this.$t('Re-auction will override old bids, are you sure to continue')
      }
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['getCurrentActiveAuction', 'doLiveBid', 'doSocketBid', 'jumpToArticle', 'CHANGE_STATE', 'doAction', 'getAuctionArticles']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_TEMP_ALERT', 'REMOVE_AUCTIONS_FETCHED_ARTICLE', 'ADD_AUCTIONS_FETCHED_ARTICLE', 'SET_DISCONNECTED_TIME', 'SET_SOCKET_DATA']),
    sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    },
    euNumbers,
    async getArticleGallery() {
      try {
        const result = await dispatcher.getArticleImages(this.currentAuction.id, this.ongoingArticle.id)
        return result;
      } catch (err) {
        console.log('Gallery images does not exist')
        return null;
      }
    },
    async onUpdateConditional () {
      if (!this.ongoingArticle) {
        return
      }
      this.disableAutoConditional = true
      this.isConditionalActive = !this.isConditionalActive
    },

    // Listen event from socket server
    setUpSocket () {
      const { socket } = SocketClient.getInstance(SocketNamespace.admins);
      if (socket) {
        socket.on('changeArticle', async data => {
          if (!_.isEmpty(this.currentAuction)) { // TODO workaround, current auction is empty for some unknown reason since merge of feature/socketUnify
            this.CHANGE_STATE({
              key: 'currentAuction',
              value: {
                ...this.currentAuction,
                highestBid: {},
                bidHistory: [],
                ongoingArticle: {
                  ...data.ongoingArticle
                }
              }
            })
          }
          this.lastSubmittedBid = null;
        })

        this.SET_SOCKET_DATA(socket)
      }
    },
    updateAskPrice (e) {
      this.forcedAskedPrice = parseFloat(e.target.value)
      this.localNextBid = this.forcedAskedPrice || null
    },
    async processBid(isBtr) {
      if (isBtr && (this.btrDisabled || this.nextBidAmount <= 0)) {
        return
      }

      //console.log(this.lastSubmittedBid)
      //console.log(this.nextBidAmount)
      if (this.lastSubmittedBid !== this.nextBidAmount) {
        this.bidSubmit = true;
        this.isFocus = false
        //let lastestNextBidAmount = this.nextBidAmount
        this.lastSubmittedBid = isBtr ? this.currentHighestBid.value : this.nextBidAmount

        const data = {
          auctionId: this.currentAuction.id,
          articleId: this.ongoingArticle.id,
          bid: isBtr ? this.currentHighestBid.value : this.nextBidAmount,
          isBtr: isBtr,
          isUser: false, // only for socket
          callback: () => {
            this.bidSubmit = false;
          }
        }

        if (!(await this.doLiveBid(data))) {
          this.lastSubmittedBid = null
        }
        //lastestNextBidAmount = resultDobId.data.highestBid.value + (resultDobId.data.bidStep?.bid_step || resultDobId.data.default_bid_step)
      }
    },
    async undoBid() {
      try {
        if (this.bidHistory && this.bidHistory.length > 0) {
          const response = await dispatcher.undoBid(this.currentAuction.id);
          this.CHANGE_STATE({
            key: 'currentAuction',
            value: response
          });
          this.lastSubmittedBid = null;
        }
      } catch (e) {
        if (e.data?.error) {
          this.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${this.$t('An error occured')}:\n
            ${this.$t('Backend error message')}: ${this.$t(e.data.data[0].errorShortText)}`
          })
        }
      }
    },
    getStopLoadingMessage () {
      return this.loadingMessage.stop
    },
    // ask bid
    async doAskBid() {
      try {
        this.articleIsStarted = true
        if (this.$refs.askValue) this.$refs.askValue.blur()
        this.isFocus = false
        let amount = this.forcedAskedPrice || this.nextBidAmount
        if (this.appSettings.processAbsenteeBids && amount > 0 && amount !== this.ongoingArticle.last_ask_price && this.ongoingArticle.last_ask_price === 0) {
          console.log('wait because of commission bid inject functionality')
          await new Promise(resolve => setTimeout(resolve, 500));
        }
        if (!this.loading && amount > 0 && amount !== this.ongoingArticle.last_ask_price) {
          this.loading = true
          const response = await dispatcher.askBid(this.currentAuction.id, amount);
          this.CHANGE_STATE([{
            key: 'currentAuction',
            value: {
              ...this.currentAuction,
              ongoingArticle: {
                ...this.currentAuction.ongoingArticle,
                last_ask_price: response.value
              }
            }
          }]);
        }
      } catch (e) {
        if (e.data?.error) {
          this.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${this.$t('An error occured')}:\n
            ${this.$t('Backend error message')}: ${this.$t(e.data.data[0].errorShortText)}`
          })
        }
      } finally {
        this.loading = false
        this.forcedAskedPrice = null
      }
    },
    countDownTimer() {
      if (this.bidWarningNumber > 0) {
        setTimeout(() => {
          this.bidWarningNumber -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.bidWarningRefresh = false
      }
    },
    // Send bid warning
    async sendBidWarning() {
      try {
        if (this.bidWarningRefresh || this.currentAuction.status !== AuctionStatusType.started) {
          return
        }
        // Show count down
        this.bidWarningRefresh = true

        await dispatcher.bidWarning(this.currentAuction.id);
        this.bidWarningNumber = 5
        this.countDownTimer()
      } catch (e) {
        if (e.data?.error) {
          this.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${this.$t('An error occured')}:\n
            ${this.$t('Backend error message')}: ${this.$t(e.data.data[0].errorShortText)}`
          })
        }
      }
    },

    async doKnockDown() {
      try {
        const { highestBid } = this.currentAuction
        if (!highestBid) {
          return
        }

        // Conditional query
        // let conditionalValue = this.isConditionalActive ? 1 : 0
        // await this.doAction({ type: 'updateConditional', articleId: this.ongoingArticle.id, value: conditionalValue, auctionId: this.currentAuction.id})

        // knockdown is from a room bidder
        if (highestBid.bidder_number === null) {
          // let generateExternalNumber      = Math.floor(1000 + Math.random() * 9000)
          let generateExternalNumber = 0
          const response = await dispatcher.sellItem(this.currentAuction.id, generateExternalNumber, this.isConditionalActive);
          this.storeExternalResponse = response

          this.showExternalKnockDownPopup = this.appSettings.enter_bidder_number
          this.isArticleSoldOut = true
          this.ongoingArticle.status = 0

          // Show bidder number without refresh
          let cloneBidHistory = _.clone(this.localBidHistory)
          cloneBidHistory[0] = { ...cloneBidHistory[0], bidder_number: _.padStart(generateExternalNumber.toString(), 4, '0') }
          this.localBidHistory = cloneBidHistory

          if (this.showExternalKnockDownPopup) {
            setTimeout(() => {
              this.$refs.refExternalBidderNumber.focus()
            }, 300)
          }
          // knockdown is from a live bidder
        } else {
          await dispatcher.sellItem(this.currentAuction.id, null, this.isConditionalActive);
          this.isArticleSoldOut = true
          this.ongoingArticle.status = 0
          // this.showKnockDownNotification(responseKnockDown)
        }
      } catch (e) {
        if (e.data?.error) {
          this.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${this.$t('An error occured')}:\n
            ${this.$t('Backend error message')}: ${this.$t(e.data.data[0].errorShortText)}`
          })
        }
      }
    },

    async doExternalKnockDown() {
      try {
        this.roomBidderSubmitted = true
        if (!this.externalBidderNumber || this.externalBidderNumber <= 0) {
          this.showExternalKnockDownPopup = false
          this.showKnockDownNotification(this.storeExternalResponse)
          return
        }

        await dispatcher.updateExternalBidderNumber(this.currentAuction.id, this.externalBidderNumber);

        if (this.storeExternalResponse?.wasLastArticle) {
          this.showKnockDownNotification(this.storeExternalResponse)
          this.externalBidderNumber = null
          this.showExternalKnockDownPopup = false
          return
        }

        // Show bidder number without refresh
        let cloneBidHistory = _.clone(this.localBidHistory)
        cloneBidHistory[0] = { ...cloneBidHistory[0], bidder_number: _.padStart(this.externalBidderNumber, 4, '0') }
        this.localBidHistory = cloneBidHistory

        this.ongoingArticle.status = 0
        this.isArticleSoldOut = true
        this.showKnockDownNotification(this.storeExternalResponse)

        this.externalBidderNumber = null
        this.showExternalKnockDownPopup = false
      } catch (e) {
        if (e.data?.error) {
          this.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${this.$t('There was an error on knockdown')}:\n
            ${this.$t('Backend error message')}: ${this.$t(e.data.data[0].errorShortText)}`
          })
        }
      }
    },
    showKnockDownNotification(res) {
      if (res.wasLastArticle) {
        this.openDialog(
          undefined,
          'alert',
          this.$t('Redirect Alert'),
          this.$t('Last article sold out. The auction will end immediately.')
        )
        setTimeout(async () => {
          this.$refs.dialog.close()
          // await this.getCurrentActiveAuction()
          // this.$router.push('/settings/auctions')
        }, 2000)
      }
      // else {
      //   this.openDialog(
      //     undefined,
      //     'alert',
      //     this.$t('Sold Out'),
      //     this.$t('This item is sold out')
      //   )
      // }
    },
    async redirectToSettings() {
      this.$refs.dialog.close()
    },
    openDialog(section, dialogType = 'confirm', ...args) {
      this.dialogFor = section;
      this.$refs.dialog.open(dialogType, ...args);
    },
    jumpToNextArticle() {
      if (!this.currentAuction) {
        return
      }
      const { ongoingArticle } = this.currentAuction
      let index = _.findIndex(this.currentAuctionArticles, ['id', ongoingArticle.id]);
      // Go to the next article
      if (index + 1 < this.currentAuctionArticles.length) {
        this.askJump(this.currentAuctionArticles[index + 1].id, this.currentAuctionArticles[index + 1].status === '0')
      }
    },

    jumpToPreviousArticle() {
      if (!this.currentAuction) {
        return
      }

      const { ongoingArticle } = this.currentAuction
      let index = _.findIndex(this.currentAuctionArticles, ['id', ongoingArticle.id]);

      // Go to the next article
      if (index > 0) {
        this.askJump(this.currentAuctionArticles[index - 1].id, this.currentAuctionArticles[index - 1].status === '0')
      }
    },

    // Jump to specific article
    async jumpArticle(articleId) {
      let soldPrice = 0
      let bidderNumber = null
      let liveBidder = false
      if (this.isArticleSoldOut) {
        soldPrice = this.currentAuction.highestBid.bid
        bidderNumber = this.currentAuction.highestBid.bidder_number
        liveBidder = this.currentAuction.highestBid.type === BidType.live
      }
      await this.jumpToArticle({ articleId, auctionId: this.currentAuction.id, prevId: this.cachedCurrentArticle, articleIsStarted: this.articleIsStarted, sold_price: soldPrice, bidder_number: bidderNumber, live_bidder: liveBidder})
    },
    // Ask to jump to specific article
    async askJump (id, reauction) {
      let updateData = [{ key: 'askJumpId', value: id }];

      if (reauction) { // Show reauction
        updateData.push({
          key: 'reauctionLoadingModal',
          value: true,
        })
        this.CHANGE_STATE(updateData)
      } else {
        if (this.isArticleSoldOut || this.bidHistory.length === 0) {
          await this.jumpArticle(id)
        } else {
          updateData.push({
            key: 'jumpLoadingModal',
            value: true,
          })
          this.CHANGE_STATE(updateData)
        }
      }
      this.roomBidderSubmitted = false
    },
    bidderNumberHandler() {
      const number = this.externalBidderNumber.toString()
      if (number.length > 5) this.externalBidderNumber = +number.slice(0, 5)
    },
    focusHandler() {
      setTimeout(() => {
        this.$refs.askValue.select()
      })
      this.enableAskFieldArrows = true
      this.isFocus = true
    },
    inputAskPriceHandler(e) {
      const expr = /^[0-9]*$/
      // allow the following keys to be able to still use the application properly
      if (!expr.test(e.key) && e.key !== 'Enter' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Backspace' && e.key !== 'Delete') {
        e.preventDefault()
      }
    },
    inputExternalBiddernumberHandler(e) {
      const expr = /^[0-9]*$/
      // allow the following keys to be able to still use the application properly
      if (!expr.test(e.key) && e.key !== 'Enter' && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Backspace' && e.key !== 'Delete') {
        e.preventDefault()
      }
    },
    reOffer() {
      this.askJump(this.currentAuction.ongoingArticle.id, true)
    },
    articleJump(state) {
      this.articleJumpRequest = state;
    },
    async syncData() {
      try {
        this.galleryImagesLoadedId = this.currentAuction.ongoingArticle && this.currentAuction.ongoingArticle.id
        if (this.currentAuction && this.currentAuction.id) {
          if (this.currentAuction.type === AuctionFlavor.live) {
            this.articlesLoading = true
            await this.getAuctionArticles({ auctionId: this.currentAuction.id })
            this.articlesLength = this.currentAuctionArticles.length
            this.articlesLoading = false
          }
        }
      } catch (e) {
        if (e.data?.error) {
          this.showSocketError(this.$t(e.data.data[0].errorShortText))
        }
      }
    },
    showSocketError(errorMessage) {
      let error = ''
      if (typeof errorMessage === 'object') {
        for (let key of Object.keys(errorMessage)) {
          const keyItem = errorMessage[key]
          error += `${keyItem}\n`
        }
      } else {
        error = errorMessage
      }
      this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: error })
    }
  },
}
</script>
<style scoped lang="scss">
.auction-wrapper {
  min-height: calc(100vh - 90px);
}
.redText {
  color: #8b141e !important;
}
.no-ongoing-article-modal {
  margin-left: 380px;
}
</style>
