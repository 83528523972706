<template>
  <v-row class="bid-step">
    <v-col align="center" cols="12">
      <v-form ref="form" class="pt-4">
        <div class="section-title">{{ $t('Bid steps') }}</div>
        <v-data-table
          :headers="disabled ? headers.slice(0, headers.length - 1) : headers"
          :items="tableItems"
          :items-per-page="100"
          hide-default-footer
          :no-data-text="noDataText"
          class="table settings remove-border-last-col"
          :class="{disabled}"
          dense
        >
          <template #[`item.start`]="{ item }">
            <v-text-field
              class="text-input"
              :disabled="disabled || tempDisabled"
              v-model.number="item.start"
              :rules="[rules.validationValidBidStepRule(item)]"
              type="number"
              @keydown="inputCharHandler"
              @keyup="inputHandler"
              min="0"
              @input="updateBidSteps"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </template>
          <template #[`item.bid_step`]="{ item }">
            <v-text-field
              class="text-input"
              :disabled="disabled || tempDisabled"
              v-model.number="item.bid_step"
              :rules="[validationRequiredRule()]"
              type="number"
              @keydown="inputCharHandler"
              @keyup="inputHandler"
              min="0"
              @input="updateBidSteps"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </template>
          <template #[`item.action`]="{ item }">
            <v-icon small @click="handleDelete(item)">
              fa-trash-can
            </v-icon>
          </template>
        </v-data-table>

        <div class="add-item-btn-container" v-if="!disabled">
          <v-btn @click="handleAdd" :disabled="tempDisabled" class="add-item-btn">
            <v-icon>fa-circle-plus</v-icon>
          </v-btn>
        </div>
        <!--        <div class="add-item-btn-container" v-if="!disabled">-->
        <!--          <v-btn type="submit" :disabled="!dataIsChanged || validationError" color="#f5f5f5">-->
        <!--            <span v-translate>Save</span>-->
        <!--          </v-btn>-->
        <!--        </div>-->
      </v-form>
    </v-col>
    <v-col align="center" cols="12" v-if="mutableCurrentAuction">
      <v-btn @click="handlerExport" class="mr-6">
        <v-icon>fa-download</v-icon>
      </v-btn>
      <al-input-file-btn
        @fileChange="handleImport"
        acceptFile=".csv"
        icon="fa-upload"
      />
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'pinia';
import { Parser } from '@json2csv/plainjs';
import AlInputFileBtn from '@/components/form/input-file'
import { processCSVFile } from '@/utils/file';
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import { AlertFlavor } from '@/api/enums';

export default {
  name: 'BidSteps',
  components: {
    AlInputFileBtn
  },
  props: {
    disabled: Boolean,
    currentAuction: Object
  },
  computed: {
    tableItems () {
      return this.disabled ? this.defaultBidSteps : this.mutableCurrentAuction.bid_steps
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('Start amount'),
          align: 'start',
          value: 'start',
          sortable: false,
        },
        {
          text: this.$t('Bid step'),
          align: 'start',
          value: 'bid_step',
          sortable: false,
        },
        {
          value: 'action',
          sortable: false,
          width: 15,
        },
      ],
      defaultBidSteps: [
        {
          start: null,
          end: null,
          bid_step: null,
        }
      ],
      noDataText: this.$t('No data available'),
      tempDisabled: false,
      dataIsChanged: false,
      validationError: false,
      mutableCurrentAuction: this.currentAuction,
      rules: {
        validationValidBidStepRule: value => {
          let prevItem = {}
          const currentIndex = this.tableItems.findIndex(el => el.id === value.id)
          if (currentIndex > 0) {
            prevItem = this.tableItems[currentIndex - 1]
            return (value && prevItem && (value.start > prevItem.start)) || 'validation.invalidBidStep'
          }
          return true
        },
      }
    }
  },
  watch: {
    currentAuction(val) {
      this.mutableCurrentAuction = val;
    }
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    validationRequiredRule: frontendValidations.required,
    handleDelete (item) {
      if (this.tempDisabled || this.disabled) {
        return;
      }

      this.mutableCurrentAuction.bid_steps = this.mutableCurrentAuction.bid_steps.filter(step => step.id ? step.id !== item.id : step.timestamp !== item.timestamp);
      this.updateBidSteps()
    },
    handleAdd () {
      if (this.tempDisabled || this.disabled) {
        return;
      }

      const lastStep = _.last(this.mutableCurrentAuction.bid_steps);
      this.mutableCurrentAuction.bid_steps = this.mutableCurrentAuction.bid_steps.concat({
        timestamp: Date.now(),
        start: (lastStep ? lastStep.start : 0) + 100,
        bid_step: this.mutableCurrentAuction.default_bid_step,
        auction_id: this.mutableCurrentAuction.id,
      })
      this.updateBidSteps()
    },

    async updateBidSteps () {
      this.$emit('onChange', this.validationError);
    },

    inputHandler () {
      this.validationError = !this.$refs.form.validate()
      this.updateBidSteps()
    },

    inputCharHandler (e) {
      const expr = /^[a-z]\d*$/
      if (expr.test(e.key) || e.key === ',' || e.key === '-') {
        e.preventDefault()
      }
    },
    handlerExport () {
      const fields = ['start', 'bid_step'];
      const opts = { fields, delimiter: ';' };

      try {
        const parser = new Parser(opts);
        const csv = parser.parse(this.mutableCurrentAuction.bid_steps);
        let a = document.createElement('a')
        a.href = `data:application/octet-stream,${encodeURIComponent(csv)}`
        a.download = this.mutableCurrentAuction.id + '-bid-steps.csv'
        a.click()
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Exporting bid-steps succeeded') })
      } catch (err) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('Exporting bid-steps failed') + '' + err })
      }
    },
    async handleImport (file) {
      const rootStore = useRootStore()
      try {
        const { data } = await processCSVFile(file);
        this.$emit('onImportBidSteps',
          data,
          'confirm',
          this.$t('Importing will delete all existing bid-steps'),
          this.$t('Do you really want to import the bid-steps?')
        );
      } catch (e) {
        rootStore.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error importing the bid-steps') })
      }
    },
  }
}
</script>
