import { defineStore } from 'pinia'
import _, { isArray } from 'lodash';
import Vue from 'vue';
import dispatcher from '@/api/dispatch';
import { i18n } from '@/main';
import { getBidderNumbersFromUser } from '@/services/user';
import useRootStore from '@/stores/rootStore';
import {
  IArticle, IBidStep,
  IBidBase,
  IUser,
  IUserJoinedAuctions, IBid, IArticleArrayChange, IArticleChange
} from '@/api/types';
import { AlertFlavor, BidderNumberStatusType } from '@/api/enums';
import moment from 'moment';
import GlobalEvents, { GlobalEventType } from '@/utils/globalEvents';
import { BidderNumberType, Role } from '@/api/enums';

interface IAuctionStoreState {
  currentAuction: Record<string, any>,
  currentAuctionArticles: IArticle[],
  knockdowns: string,
  loadingModal: boolean,
  loadingType: string,
  selectedArticle: IArticle,
  showPushNotiTooltip: boolean,
  stopLoadingModal: boolean,
  jumpLoadingModal: boolean,
  askJumpId: number,
  reauctionLoadingModal: boolean,
  footerCrawler: boolean,
  userData: IUser,
  reOffered: boolean,
  joinModal: boolean,
  articlesListActive: boolean,
  isAdminLogout: boolean,
  joinedLiveAuctions: IUserJoinedAuctions,
  auctionJoined: number,
  auctionsFetchedArticles: IArticle[],
  lastUpdatedAdminArticles: Record<string, Date>,
  lastUpdatedArticles: Record<string, Date>
}

const useAuctionStore = defineStore('auctionStore', {
  state (): IAuctionStoreState {
    return {
      currentAuction: {} as any, // the current active live auction
      currentAuctionArticles: [], // all articles from the current active live auction
      knockdowns: '', // knockdowns? (not used)
      loadingModal: true, // flag to indicate if the loading modal during the auction should be displayed (i.e. "The auction is paused")
      loadingType: 'loading', // the type of the message to display in the loading modal
      selectedArticle: null, // currently sold article on the live auction
      showPushNotiTooltip: false, // flag to indicate if the push notification for the bid warning ("the article is about to be sold. Bid now") should be displayed
      stopLoadingModal: false, // flag to indicate if the stop auction modal is displayed on the admin auction view
      jumpLoadingModal: false, // flag to indicate if the jump to article modal is displayed on the admin auction view
      askJumpId: null, // the article id if the article to be jumped to
      reauctionLoadingModal: false, // flag to indicate if the reauction article modal is displayed on the admin auction view
      footerCrawler: false, // ? (not used)
      userData: null, // the current user data i.e. registered count
      reOffered: false, // flag to indicate if an article is re-offered (called again after knockdown)
      joinModal: false, // flag to indicate if the auction join modal is displayed
      articlesListActive: true, // flag to indicate if the article slider is displayed on the admin auction view
      isAdminLogout: false, // flag to indicate if the admin logged out during the auction (this is, clicked the logout button)
      joinedLiveAuctions: null, // running live auction join state for the current user
      auctionJoined: null, // auction id of the joined live auction, will be set to null on logout or kickout (this only updates if the user clicks on "yes" in the modal. It can be
      auctionsFetchedArticles: [], // fetched articles from an auction for product catalogue etc. (not for a running live auction)
      lastUpdatedAdminArticles: {}, // the last time the articles have been updated for the admin frontend
      lastUpdatedArticles: {} // the last time the articles have been updated for the user frontend
    };
  },
  getters: {
    // Current article to be sold
    ongoingArticle: (state: any): IArticle => { //TODO return null if theres no ongoing article
      if (_.isEmpty(state.currentAuction.ongoingArticle)) {
        return {} as IArticle
      } else {
        if (state.selectedArticle === null) {
          return state.currentAuction.ongoingArticle
        } else {
          return state.selectedArticle
        }
      }
    },

    // Get current highest bid
    currentHighestBid: (state: any): IBidBase => {
      if (_.isEmpty(state.currentAuction.ongoingArticle)) {
        return { value: 0, id: null, bidder_number: 0, type: null }
      } else { // If this is no bid yet, return the limit step
        return state.currentAuction.highestBid?.value
          ? { value: state.currentAuction.highestBid.value, id: state.currentAuction.highestBid.user_id, bidder_number: state.currentAuction.highestBid.bidder_number, type: state.currentAuction.highestBid.type }
          : { value: 0, id: null, bidder_number: 0, type: null }
      }
    },

    // Get next bid amount
    nextBidAmount: (state: any): number => {
      const { ongoingArticle, highestBid, bidStep, default_bid_step: defaultBidStep } = state.currentAuction;
      if (_.isEmpty(ongoingArticle)) {
        return 0;
      } else {
        if (!highestBid || !highestBid.value) {
          return ongoingArticle.last_ask_price > 0 ? ongoingArticle.last_ask_price : ongoingArticle.limit
        } else {
          const step = bidStep ? bidStep.bid_step : defaultBidStep
          const startVal = bidStep ? bidStep.start : 0
          const fullDivided = (highestBid.value - startVal) % step
          const expr = Math.ceil((highestBid.value - startVal) / step)
          const higherInTimes = fullDivided === 0 ? expr + 1 : expr
          return highestBid.value >= ongoingArticle.last_ask_price ? startVal + step * higherInTimes : ongoingArticle.last_ask_price
        }
      }
    },

    // Get next bid amount from the backend
    nextBidAmountFromBackend: (state: any): number => {
      const { ongoingArticle, nextBid } = state.currentAuction;
      if (_.isEmpty(ongoingArticle)) {
        return 0;
      } else {
        if (_.isEmpty(nextBid)) {
          const result = state.nextBidAmount;
          console.log(`next bid hint is missing from the backend, calculating it (${result})`)
          return result
        } else {
          return nextBid.next_ask_price
        }
      }
    },

    // Get current bid step
    currentStepValue: (state: any): IBidStep => {
      return state.currentAuction.bidStep ? state.currentAuction.bidStep : { bid_step: state.currentAuction.default_bid_step }
    },

    /*
    // Get current user data (UNUSED)
    usersData: (state: any): any => {
      return state.currentAuction.userData
    },
     */

    // Get bid history
    bidHistory(state: any): IBid[] {
      return state.currentAuction.bidHistory
        ? state.currentAuction.bidHistory.map((item: any, index: number) => {
          item.bidder_number = item.bidder_number ? _.padStart(item.bidder_number, 4, '0') : '----'
          return item;
        })
        : []
    }
  },
  actions: {
    CHANGE_STATE(object: any): void {
      if (isArray(object) && object.length > 0) {
        object.forEach((item: any) => {
          this[item.key] = item.value
        })
      } else {
        this[object.key] = object.value
      }
    },
    /*
    UPDATE_ARTICLE_STATUS(data: any) {
      let index = _.findIndex(this.currentAuctionArticles, ['id', data.id]);
      Vue.set(this.currentAuctionArticles, index, data.data)
    },
     */
    TOGGLE_CRAWLER(data: boolean): void {
      this.footerCrawler = data
    },
    SET_USER_DATA(data: IUser): void {
      this.userData = data
    },
    SET_IS_ADMIN_LOGOUT (data: boolean): void {
      this.isAdminLogout = data
    },
    /**
     * Get the current active auction data and update the store
     * @return {Promise<any>} - Promise containing the current active auction data
     */
    async getCurrentActiveAuction (): Promise<any> {
      const rootStore = useRootStore();
      try {
        let result = await dispatcher.getCurrentActiveAuction();

        // Update current auction
        const auctionData = _.isEmpty(result) ? {} : result;
        this.CHANGE_STATE({key: 'currentAuction', value: auctionData});
        if (_.isEmpty(result)) this.CHANGE_STATE({key: 'currentAuctionArticles', value: []})
        return result;
      } catch (e: any) {
        if (e.data?.error && this.userData.role === Role.admin) {
          rootStore.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${i18n.t('An error occured')}:\n
            ${i18n.t('Backend error message')}: ${i18n.t(e.data.data[0].errorShortText)}`
          });
        }
        this.CHANGE_STATE({ key: 'currentAuction', value: {} });
        return e.data
      }
    },
    /**
     * Get the articles from the current active auction and update the store
     * @return {Promise<any>} - Promise containing the current active auction data
     */
    async getAuctionArticles (payload: any): Promise<IArticle[]> {
      const rootStore = useRootStore();
      const isUser = rootStore.isAuthenticatedAsUser && this.userData.role === Role.user;
      const isGuest = !rootStore.isAuthenticatedAsUser
      try {
        let userBidderNumbers
        if (isUser) {
          const bidderNumbers = await getBidderNumbersFromUser(this.userData.id)
          userBidderNumbers = bidderNumbers.filter(el => el.auction_id === payload.auctionId && el.type === BidderNumberType.live && el.user_status === BidderNumberStatusType.unlocked)
        }

        let knockdownsResp
        if (this.userData && (this.userData.role === Role.admin || (this.userData.role === Role.user && !_.isEmpty(userBidderNumbers)))) {
          knockdownsResp = await dispatcher.getKnockdownsArticleSlider(payload.auctionId)
        } else {
          knockdownsResp = null
        }

        try {
          let result;
          if (isGuest) {
            result = await dispatcher.getAuctionArticlesGuest(payload.auctionId);
          } else {
            result = await dispatcher.getAuctionArticles(payload.auctionId);
          }

          // Update current auction
          let auctionNumber = payload.auctionCode
          let mediaServer = payload.mediaServer
          const articlesWithImages = result.map((itm: any) => {
            const updatedVal = knockdownsResp ? knockdownsResp.find((el: any) => el.article_id === itm.id) : null
            let articleNumber = itm.number
            let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
            if (updatedVal) return { ...itm, image: imageUrl, ...updatedVal }
            return { ...itm, image: imageUrl }
          })
          this.CHANGE_STATE({ key: 'currentAuctionArticles', value: articlesWithImages || [] });
          return result;
        } catch (e: any) {
          this.CHANGE_STATE({ key: 'currentAuctionArticles', value: [] });
          if (e.data?.error) {
            rootStore.SET_TEMP_ALERT({
              flavor: AlertFlavor.error,
              content: `${i18n.t('An error occured')}:\n
              ${i18n.t('Backend error message')}: ${i18n.t(e.data.data[0].errorShortText)}`
            });
            return e.data
          }
        }
      } catch (e) {
        rootStore.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: i18n.t('There was an error loading the articles. Please try again later') });
      }
    },
    /**
     * Get articles from one auction
     * @return {Promise<void>} - Promise containing nothing
     */
    async getArticles (auctionId: number): Promise<void> {
      const rootStore = useRootStore();
      try {
        let timestampResponse;
        if (rootStore.isAuthenticatedAsUser) {
          timestampResponse = await dispatcher.checkIfAuctionArticlesUpdated(auctionId);
        } else {
          timestampResponse = await dispatcher.checkIfAuctionArticlesUpdatedGuest(auctionId);
        }
        const timestampResponseTime = timestampResponse.updated_at;
        if (this.lastUpdatedArticles[auctionId.toString()] === undefined) {
          console.log('doing full article fetch')
          let articles;
          if (rootStore.isAuthenticatedAsUser) {
            articles = await dispatcher.getAuctionArticles(auctionId)
          } else {
            articles = await dispatcher.getAuctionArticlesGuest(auctionId)
          }
          this.SET_AUCTIONS_FETCHED_ARTICLES({ auctionID: auctionId, articles: articles });
          this.lastUpdatedArticles[auctionId.toString()] = timestampResponseTime;
        } else {
          const responseUpdateMoment = moment(timestampResponseTime)
          const articlesLastUpdatedMoment = moment(this.lastUpdatedArticles[auctionId.toString()])
          if (responseUpdateMoment.diff(articlesLastUpdatedMoment, 'seconds') > 0) {
            console.log('articles changed, doing dynamic article fetch')
            const dynamicArticles = await dispatcher.getArticlesDynamic(auctionId)
            const currentArticles = this.auctionsFetchedArticles[`auction_${auctionId}`]

            let updatedArticles = 0;
            const articles = currentArticles.map(el => {
              const target = dynamicArticles.find((article: { id: any }) => el.id === article.id)
              if (target) {
                updatedArticles++
                return {
                  ...el,
                  ...target
                }
              }
              return el
            });
            console.log(`updated ${updatedArticles} articles`);
            this.SET_AUCTIONS_FETCHED_ARTICLES({ auctionID: auctionId, articles: articles });
            this.lastUpdatedArticles[auctionId.toString()] = timestampResponseTime;
          } else {
            console.log('articles unchanged, skipping fetch')
          }
        }
      } catch (e) {
        rootStore.SET_TEMP_ALERT({
          flavor: AlertFlavor.error,
          content: i18n.t('There was an error loading the data. Please try again later')
        })
      }
    },
    /**
     * Get articles from one auction for the admin frontend and update the store
     * @return {Promise<void>} - Promise containing nothing
     */
    async getAdminArticles (auctionId: number): Promise<void> {
      const rootStore = useRootStore();
      try {
        const timestampResponse = await dispatcher.checkIfAuctionArticlesUpdated(auctionId)
        const timestampResponseTime = timestampResponse.updated_at;
        let doFetch = false;
        if (this.lastUpdatedAdminArticles[auctionId.toString()] === undefined) {
          doFetch = true;
        } else {
          const responseUpdateMoment = moment(timestampResponseTime)
          const usersLastUpdatedMoment = moment(rootStore.lastUpdatedUsers)
          if (responseUpdateMoment.diff(usersLastUpdatedMoment, 'seconds') > 0) {
            doFetch = true;
          }
        }

        if (doFetch) {
          console.log('doing full admin articles fetch')
          const response = await dispatcher.getAuctionArticles(auctionId)
          this.SET_AUCTIONS_FETCHED_ARTICLES({
            auctionID: auctionId,
            articles: response
          });
          this.lastUpdatedAdminArticles[auctionId.toString()] = timestampResponseTime;
        } else {
          console.log('admin articles unchanged, skipping fetch')
        }
      } catch (e) {
        rootStore.SET_TEMP_ALERT({
          flavor: AlertFlavor.error,
          content: i18n.t('There was an error loading the data. Please try again later')
        });
      }
    },
    /*
    async startAuction (auctionId: number) {
      try {
        let result = await dispatcher.startAuction(auctionId);
        await this.getCurrentActiveAuction();

        return result;
      } catch (e) {
        return e
      }
    },
     */
    async jumpToArticle (data: any): Promise<any> {
      const rootStore = useRootStore();
      try {
        console.log(data.auctionId)
        let result = await dispatcher.jumpToArticle(data.auctionId, data.articleId);

        if (data.prevId) {
          const prevArticle = this.currentAuctionArticles.find((item: any) => item.id === data.prevId)
          prevArticle.sold_price = data.sold_price
          prevArticle.bidder_number = data.bidder_number
          prevArticle.live_bidder = data.live_bidder
          if (prevArticle.sold_price > 0 && prevArticle.last_ask_price > 0) {
            prevArticle.status = '0'
          } else if (prevArticle.sold_price === 0) {
            if (data.articleIsStarted) {
              prevArticle.status = '4'
            } else {
              prevArticle.status = '3'
            }
          }
        }
        return result;
      } catch (e: any) {
        if (e.data?.error) {
          rootStore.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${i18n.t('An error occured')}:\n
            ${i18n.t('Backend error message')}: ${i18n.t(e.data.data[0].errorShortText)}`
          });
          return e.data;
        }
      }
    },
    async doAction (data: any): Promise<any> {
      const rootStore = useRootStore();
      try {
        let result = await dispatcher.action(data);
        if (data.type === 'ask') {
          this.CHANGE_STATE([{
            key: 'currentAuction',
            value: {
              ...this.currentAuction,
              ongoingArticle: {
                ...this.currentAuction.ongoingArticle,
                last_ask_price: data.value
              }
            }
          }]);
        } else if (data.type !== 'sellItem' || data.type !== 'bidWarning') {
          await this.getCurrentActiveAuction();
        }
        return result;
      } catch (e: any) {
        if (e.data?.error) {
          rootStore.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${i18n.t('An error occured')}:\n
            ${i18n.t('Backend error message')}: ${i18n.t(e.data.data[0].errorShortText)}`
          });
          return e.data
        }
      }
    },
    async doLiveBid (uploadData: any): Promise<boolean> {
      const rootStore= useRootStore();
      try {
        await dispatcher.doLiveBid(uploadData);
        console.log(`Bid sent with value ${uploadData.bid}`)
        return true;
      } catch (e: any) {
        if (e.data?.error && (!uploadData.isUser || (e.data.data[0].errorShortText === 'bid.plausibility.bid.again' || e.data.data[0].errorShortText === 'user.bid.bidlimit.reached'))) {
          rootStore.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: `${i18n.t(e.data.data[0].errorShortText)}`
          });
        }
        return false;
      } finally {
        if (uploadData.callback) {
          uploadData.callback();
        }
      }
    },
    /**
     * Get Knockdowns for an auction and update the store
     * @param {number} auctionId - The auction id
     * @return {Promise<any>} - Promise containing the knockdowns of the auction or exception data
     */
    async getAdminKnockdownsPerAuction (auctionId: number): Promise<any> {
      try {
        let result = await dispatcher.getAdminKnockdownsPerAuction(auctionId, false);

        this.CHANGE_STATE({ key: 'knockdowns', value: result });
        return result;
      } catch (e: any) {
        this.CHANGE_STATE({ key: 'knockdowns', value: '' });
        return e.data
      }
    },
    /**
     * Add an article to an auction and update the store
     * @param {any} data - payload
     * @return {Promise<any>} - Promise containing the created article or null on error
     */
    async addArticle (data: any): Promise<IArticle> {
      const rootStore = useRootStore();
      const { auctionId, article } = data;

      try {
        const result = await dispatcher.createArticle(auctionId, {
          ...article,
          auction_id: auctionId
        });

        this.ADD_AUCTIONS_FETCHED_ARTICLE({
          auctionID: auctionId,
          article: result
        });
        return result;
      } catch (e: any) {
        console.log(e)
        if (e.data?.error && e.data.data[0].errorShortText === 'admin.article.exist') {
          rootStore.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: i18n.t('The article number already exists')
          });
        } else {
          rootStore.SET_TEMP_ALERT({
            flavor: AlertFlavor.error,
            content: i18n.t('There was an error creating the article')
          });
        }
        return null;
      }
    },
    /**
     * Update an article of an auction and update the store
     * @param {any} data - payload
     * @return {Promise<any>} - Promise containing the updated article or null on error
     */
    async updateArticle (data: any): Promise<IArticle> {
      const rootStore = useRootStore();
      const { auctionId, article } = data;

      try {
        const result = await dispatcher.updateArticle(auctionId, article.id, article);

        this.UPDATE_AUCTIONS_FETCHED_ARTICLE({
          auctionID: auctionId,
          article: result
        });
        return result;
      } catch (e: any) {
        rootStore.SET_TEMP_ALERT({
          flavor: AlertFlavor.error,
          content: i18n.t('There was an error changing values')
        });
        return null;
      }
    },
    /**
     * Get the auction joined state for current user
     * @return {Promise<any>} - Promise containing the updated article or null on error
     */
    async getAuctionJoinedState(): Promise<IUserJoinedAuctions> {
      try {
        let result = await dispatcher.getAuctionJoinStatus();

        this.CHANGE_STATE({key: 'joinedLiveAuctions', value: result});
        return result;
      } catch (e) {
        this.CHANGE_STATE({ key: 'joinedLiveAuctions', value: null});
        return null;
      }
    },
    /**
     * Join an auction (gains access to auction events on the socket), errors will be handled outside
     */
    async joinAuction (auctionId: number): Promise<void> {
      const rootStore = useRootStore();
      try {
        if (!this.auctionJoined) {
          await dispatcher.joinLiveAuction(auctionId, rootStore.browserTabId) // exception will be handled in the join auction component
          this.CHANGE_STATE({ key: 'auctionJoined', value: auctionId });
          await this.getCurrentActiveAuction();
        }
      } catch (e) {
        rootStore.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: i18n.t('There was an error joining the auction. Please try again later') });
      }
    },
    /**
     * Leave the auction, makes sense only if the auction has been joined before
     */
    async leaveAuction (auctionId: number, force: boolean): Promise<void> {
      try {
        const rootStore = useRootStore();
        if (force || this.auctionJoined) await dispatcher.leaveLiveAuction(auctionId, rootStore.browserTabId)
      } catch (e) {
        // happens if offline and the user clicks logout since there is no socket connection and api will also fail
      }
      if (this.auctionJoined) this.CHANGE_STATE({key: 'auctionJoined', value: null});
    },
    SET_AUCTIONS_FETCHED_ARTICLES (payload: IArticleArrayChange): void {
      Vue.set(this.auctionsFetchedArticles, `auction_${payload.auctionID}`, payload.articles)
      // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
      GlobalEvents.emitEvent(GlobalEventType.updateArticle)
    },
    UPDATE_AUCTIONS_FETCHED_ARTICLE (payload: IArticleChange): void {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        const idx = this.auctionsFetchedArticles[`auction_${payload.auctionID}`].findIndex((el: any) => el.id === payload.article.id)
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].splice(idx, 1, {
          ...this.auctionsFetchedArticles[`auction_${payload.auctionID}`][idx],
          ...payload.article
        })
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
    REMOVE_AUCTIONS_FETCHED_ARTICLE (payload: IArticleChange): void {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        const idx = this.auctionsFetchedArticles[`auction_${payload.auctionID}`].findIndex((el: any) => el.id === payload.article.id)
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].splice(idx, 1)
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
    ADD_AUCTIONS_FETCHED_ARTICLE (payload: IArticleChange): void {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].push(payload.article)
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
  }
})

export default useAuctionStore
