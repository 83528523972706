<template>
  <v-card class="send-message-modal px-4 pt-4" :class="{ active: dialog }">
    <v-btn class="close-btn" icon @click="closeModal"><v-icon>fa-times</v-icon></v-btn>
    <v-card-title class="justify-center pb-0">
      <span class="headline">{{ $t('Send messages') }}</span>
    </v-card-title>
    <h5 class="my-4">{{ $t('Recipients') }}</h5>
    <v-form ref="form" v-model="isFormValid">
      <v-select
        class="input-field mt-0"
        :items="typeItems"
        :label="$t('Recipient type')"
        v-model="type"
      />
      <v-select
        class="input-field mt-0"
        :items="roles"
        :label="$t('Roles')"
        multiple
        chips
        deletable-chips
        :rules="[validationRequiredRoles(type)]"
        v-model="selectedRoles"
        v-if="type === 'roles'"
      >
        <template #message="{ message }">
          {{ $t(message) }}
        </template>
      </v-select>
      <v-autocomplete
        class="input-field mt-0"
        :items="connectedUsers"
        :label="$t('Users')"
        multiple
        chips
        :rules="[validationRequiredUsers(type)]"
        deletable-chips
        v-model="selectedUsers"
        v-else
      >
        <template #message="{ message }">
          {{ $t(message) }}
        </template>
      </v-autocomplete>
      <v-row>
        <v-col cols="6">
          <v-select
            class="input-field mt-0"
            :items="messageTypes"
            :label="$t('Type')"
            v-model="selectedType"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            :label="$t('Duration (seconds)')"
            :rules="[validationPositiveNumber()]"
            class="input-field mt-0"
            v-model.number="duration"
            @keydown="inputCharHandler"
          >
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-checkbox
        hide-details
        class="input-field"
        v-model="dismissible"
        :label="$t('Dismissible')"
      />
      <v-textarea
        :label="$t('Message')"
        class="input-field"
        rows="6"
        :counter="maxInputLength"
        clearable
        v-model="message"
        :rules="[validationRequired(), validationLessThanMaximum(maxInputLength)]"
        no-resize
      >
        <template #message="{ message }">
          {{ $t(message) }}
        </template>
      </v-textarea>
      <v-btn
        class="btn primary-btn success full-width"
        :disabled="!isFormValid"
        @click="sendMessage"
        :loading="loading"
      >
        {{ $t('Submit') }}
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from 'pinia'
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { AlertFlavor } from '@/api/enums';
import { Role } from '@/api/enums';

export default {
  name: 'SendMessageModal',
  props: {
    title: {},
    text: {}
  },
  data() {
    return {
      dialog: false,
      type: 'roles',
      selectedRoles: ['all'],
      selectedUsers: [],
      selectedType: 'info',
      duration: 5,
      dismissible: true,
      message: '',
      loading: false,
      typeItems: [
        {
          text: this.$t('Roles'),
          value: 'roles'
        },
        {
          text: this.$t('Users'),
          value: 'users'
        }
      ],
      messageTypes: [
        {
          text: this.$t('Info'),
          value: 'info'
        },
        {
          text: this.$t('Success'),
          value: 'success'
        },
        {
          text: this.$t('Warning'),
          value: 'warning'
        },
        {
          text: this.$t('Error'),
          value: 'error'
        }
      ],
      maxInputLength: 500,
      isFormValid: false
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction']),
    ...mapState(useRootStore, ['userProfile', 'clientList', 'socket']),
    connectedUsers() {
      return this.clientList.map(client => {
        return {
          text: `${client.first_name} ${client.last_name}`,
          value: client.id
        }
      })
    },
    roles() {
      const roles = [{ text: this.$t('All'), value: 'all' }]
      for (let role in Role) {
        roles.push({ text: this.$t(role), value: role })
      }
      return roles
    }
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT', 'SET_SEND_MESSAGE_MODAL']),
    validationRequired: frontendValidations.required,
    validationRequiredUsers: frontendValidations.requiredUsers,
    validationRequiredRoles: frontendValidations.requiredRoles,
    validationPositiveNumber: frontendValidations.positiveNumber,
    validationLessThanMaximum: frontendValidations.lessThanMaximum,
    openModal() {
      this.SET_SEND_MESSAGE_MODAL(true)
      this.dialog = true
    },
    closeModal() {
      this.SET_SEND_MESSAGE_MODAL(false)
      this.dialog = false
    },
    inputCharHandler (e) {
      const expr = /^[a-z]\d*$/
      if (expr.test(e.key) || e.key === ',' || e.key === '-' || e.key === '.' || e.key === '/') {
        e.preventDefault()
      }
    },
    async sendMessage() {
      const data = {
        content: this.message,
        flavor: this.selectedType,
        timeout: this.duration === 0 ? null : this.duration * 1000,
        dismissable: this.dismissible,
        additionalAction: '',
        recipients: []
      }
      if (this.type === 'roles') {
        if (this.selectedRoles.find(role => role === 'all')) {
          const roles = []
          for (let role in Role) {
            roles.push({ text: this.$t(role), value: role })
          }
          data.recipients = roles.map(role => ({ role: role.value }))
        } else {
          data.recipients = this.selectedRoles.map(role => ({ role }))
        }
      } else {
        data.recipients = this.selectedUsers.map(user => ({ id: user }))
      }

      this.loading = true
      let calledToSocket = true
      setTimeout(() => {
        if (calledToSocket) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error sending the message') })
          this.loading = false
        }
      }, 3000)
      try {
        await dispatcher.sendMessageOnSocket(data);
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Message sent successfully') })
        this.type = 'roles'
        this.selectedRoles = ['all']
        this.selectedUsers = []
        this.selectedType = 'info'
        this.duration = 5
        this.message = ''
        this.validation = false
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error sending the message') })
      } finally {
        this.loading = false
        calledToSocket = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
.send-message-modal {
  position: fixed;
  width: 500px;
  right: 0;
  top: 0;
  z-index: 1000;
  height: 100vh;
  overflow: auto;
  transform: translate(100%, 0);
  padding-bottom: 45px !important;
  box-shadow: none !important;
  transition: all .4s ease;
  .v-data-table {
    max-height: calc(100vh - 180px);
    overflow: auto;
  }
  .vue-ads-flex {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
  }
  &.active {
    transform: translate(0, 0);
  }
}
</style>
