<template>
  <div class="footer app-footer-bottom">
    <v-row class="justify-space-between align-center flex-nowrap">
      <div class="app-logo-version">
        <a href="https://www.bid4it.de" target="_blank">
          <span class="footer-logo">
            <v-img :src="require('@/assets/images/logo_new.png')" width="50px" height="50px"/>
          </span>
        </a>
        <span class="footer-version mb-1">
            {{ appVersion }}
        </span>
      </div>
      <div class="footer-links" v-if="!isViewer">
        <a @click="$router.push({ name: 'infos' })" class="customizable">
          {{ $t('Browser support and further information') }}
        </a> -
        <a @click="$router.push({ name: 'imprint' })" class="customizable">
          {{ $t('Imprint') }}
        </a> -
        <a :href="appSettings.url_dataprotection" target="_blank" class="customizable">
          {{ $t('Data protection') }}
        </a>
      </div>
      <div class="footer-copyright" />
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import useRootStore from '@/stores/rootStore';
import { Role } from '@/api/enums';

export default {
  name: 'ApplicationFooter',
  props: {},
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appSettings']),
    currentYear() {
      return new Date().getFullYear()
    },
    isViewer() {
      return this.userProfile.role === Role.viewer
    },
    appVersion() {
      return process.env.APP_VERSION
    }
  },
  methods: {
  },
  data: () => ({
    loading: false,
    openModal: false,
    modalPosition: false,
    error: false,
    modalKey: 0,
    activePageName: ''
  }),
}
</script>
<style lang="scss">
// Modal styles

.static-page-content {
  color: black;
  font-size: 16px;

  p {
    margin-bottom: 12px;
  }

  .static-page-title {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .static-page-text {
    text-align: left;
  }

  h1 {
    font-size: 22px;
    line-height: 1.33;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 20px;
    line-height: 1.33;
    font-weight: bold;
    margin-bottom: 18px;
  }

  h3, h4, h5, h6 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  ul {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.app-footer-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 35px;
  z-index: 10;
  // @media(max-width:760px){
  //     background: white;
  // }
  .app-logo-version {
    text-align: left;
    margin: 5px 0;
    display: flex;
    align-items: flex-end;

    .footer-logo {
      width: 50px;
      display: inline-block;
      outline: none;

      a {
        outline: none;
        text-decoration: none;
      }
    }

    .footer-version {
      font-size: 14px;
      line-height: 20px;
      opacity: 0.85;
      margin: 0 0 0 10px;
    }
  }

  .footer-copyright {
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    opacity: .85;
    @media(max-width: 760px) {
      font-size: 8px;
      padding-top: 9px;
    }

    a {
      color: inherit;
    }
  }

  .footer-links {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    opacity: .85;
    @media(max-width: 760px) {
      font-size: 12px;
      padding-top: 9px;
      margin-bottom: 9px;
    }

    a {
      color: inherit;
    }
  }
}
</style>
<style lang="scss" scoped>
.modal-header {
  padding: 40px 0;
}
.back-btn-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  left: 30px;
  top: -20px;
  cursor: pointer;
  @media(max-width: 760px) {
    left: 12px;
    top: -28px;
  }
}
.back-grey-text {
  position: relative;
  top: 0;
  left: 0;
  margin-left: 10px;
}
.header-lang-switcher {
  position: relative;
  right: 0;
  width: 195px;
  top: 0;
}
</style>
