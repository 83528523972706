import SocketClient from '@/utils/socket';
import * as Sentry from '@sentry/vue';
import {localFeatures} from '@/utils/constants';
import {sendTelemetry} from "@/utils";
import { SocketNamespace } from '@/api/enums';

const socketRequests = {
  getUserBidderNumbers: async (userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId
      }
      socket.emit('getUserBidderNumbers', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getUserBidderNumbers", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserBidderNumbers", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getUserProfile: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId: 'me'
      }
      socket.emit('getUserProfile', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getUserProfile", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserProfile", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  updateUserProfile: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload = {
        userId: data.id,
        userData: data
      }
      socket.emit('updateUserProfile', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: updateUserProfile", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateUserProfile", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getAuctions: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getAuctions', {}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getAuctions", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAuctions", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  requestSignup: async (auctionId: number, userId: number, bidLimit?: number, receiveStartReminderEmail?: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        userId,
        auctionId
      };
      if (bidLimit !== null) {
        payload.signup_data = {
          bidLimit
        };
      }
      if (receiveStartReminderEmail !== undefined) {
        payload.signup_data = {
          ...payload.signup_data,
          notificationAuctionStart: receiveStartReminderEmail
        }
      }
      socket.emit('requestAuctionSignup', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: requestSignup", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: requestSignup", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  sendEmailInquiry: async (userId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        userId,
        email_data: data,
      };
      socket.emit('sendInquiryMail', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: sendEmailInquiry", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: sendEmailInquiry", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getWishlist: async (auctionId: number, userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId
      };
      socket.emit('getUserWishlist', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getWishlist", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getWishlist", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  addToWishlist: async (auctionId: number, userId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId,
        articleId
      };
      socket.emit('addToUserWishlist', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: addToWishlist", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: addToWishlist", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  removeFromWishlist: async (auctionId: number, userId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId,
        articleId
      };
      socket.emit('deleteFromUserWishlist', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: removeFromWishlist", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: removeFromWishlist", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getAllCommissionBids: async (auctionId: number, getUserData: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        articleId: 'all'
      };
      if (getUserData) {
        payload.getUserData = true
      }
      socket.emit('getArticleCommissionBids', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getAllCommissionBids", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAllCommissionBids", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  setCommissionBids: async (auctionId: number, data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        commission_bids: data.commission_bids
      };
      socket.emit('placeArticleCommissionBids', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: setCommissionBids", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: setCommissionBids", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getUserKnockdowns: async (auctionId: number, userId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        userId
      };
      socket.emit('getUserKnockdownsPerAuction', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getUserKnockdowns", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getUserKnockdowns", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getKnockdownsArticleSlider: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('getUserKnockdownsPerAuctionArticleSlider', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getKnockdownsArticleSlider", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getKnockdownsArticleSlider", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  searchArticles: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getArticlesByQuery', data, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: searchArticles", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: searchArticles", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  validateCommissionBids: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('validateCommissionBids', data, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: validateCommissionBids", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: validateCommissionBids", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  sendMessageOnSocket: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('sendMessage', data, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: sendMessageOnSocket", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: sendMessageOnSocket", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getCurrentActiveAuction: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      socket.emit('getActiveAuctionState', {}, async (response) => { //TODO backend can handle null
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getCurrentActiveAuction", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getCurrentActiveAuction", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  doLiveBid: async (data): Promise<any> =>
    new Promise((resolve, reject) => {
      const {auctionId, articleId, bid, isUser, isBtr} = data;
      const socketName = isUser ? SocketNamespace.users : SocketNamespace.admins;
      const { socket } = SocketClient.getInstance(socketName);
      const payload: any = {
        auctionId,
        articleId,
        bid,
        isBtr,
        timestamp: Date.now()
      };
      socket.emit('liveBid', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: doLiveBid", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: doLiveBid", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  jumpToArticle: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        articleId
      };
      socket.emit('jumpToArticle', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: jumpToArticle", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: jumpToArticle", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  undoBid: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('undoBid', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: undoBid", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: undoBid", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  askBid: async (auctionId: number, value: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        value
      };
      socket.emit('askBid', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: askBid", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: askBid", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  bidWarning: async (auctionId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId
      };
      socket.emit('bidWarning', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: bidWarning", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: bidWarning", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  updateExternalBidderNumber: async (auctionId: number, bidderNumber: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        bidderNumber
      };
      socket.emit('updateExternalBidderNumber', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: updateExternalBidderNumber", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateExternalBidderNumber", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  sellItem: async (auctionId: number, bidderNumber: number, conditional: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const { socket } = SocketClient.getInstance();
      let payload: any = {
        auctionId,
        conditional
      };
      if (bidderNumber !== null) {
        payload.bidderNumber = bidderNumber;
      }
      socket.emit('sellItem', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: sellItem", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: sellItem", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  joinLiveAuction: async (auctionId: number, browserTabFingerprint: string): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        browserTabFingerprint
      };
      socket.emit('joinAuction', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: joinLiveAuction", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: joinLiveAuction", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  leaveLiveAuction: async (auctionId: number, browserTabFingerprint: string): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      const payload: any = {
        auctionId,
        browserTabFingerprint
      };
      socket.emit('leaveAuction', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: leaveLiveAuction", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: leaveLiveAuction", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getAuctionJoinStatus: async (): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      const payload: any = {
        userId: "me"
      };
      socket.emit('getUserJoinStatus', payload, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getAuctionJoinStatus", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAuctionJoinStatus", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  action: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('action', data, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: action", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: action", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getAdminKnockdownsPerAuction: async (auctionId: number, csv: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('getAdminKnockdownsPerAuction', {auctionId, csv}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getAdminKnockdownsPerAuction", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getAdminKnockdownsPerAuction", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getArticleTimedStatus: async (auctionId: number, articleId: number | string): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('getTimedStatus', {auctionId, articleId}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getArticleTimedStatus", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getArticleTimedStatus", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  placeTimedBid: async (auctionId: number, articleId: number, bid: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('timedBid', {auctionId, articleId, maximum_bid: bid}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: placeTimedBid", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: placeTimedBid", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getCategories: async (getLastUpdatedTimestamp: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('getCategories', {getLastUpdatedTimestamp}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getCategories", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getCategories", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getCategoriesGuest: async (getLastUpdatedTimestamp: boolean): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('getCategoriesGuest', {getLastUpdatedTimestamp}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: getCategoriesGuest", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: getCategoriesGuest", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  instantBuy: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('instantBuy', {auctionId, articleId}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: instantBuy", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: instantBuy", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  updateCategory: async (data: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('updateCategory', data, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: updateCategory", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: updateCategory", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  deleteCategory: async (categoryId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('deleteCategory', {categoryId}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: deleteCategory", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: deleteCategory", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  getArticleImages: async (auctionId: number, articleId: number): Promise<any> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('getArticleImages', {auctionId, articleId}, async (response) => {
        if (response.data && !response.data.error) {
          resolve(response);
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: sendClientTelemetry", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              await sendTelemetry("Backend REST call failed: sendClientTelemetry", 500, JSON.stringify(response.data));
            }
          }
          reject(response);
        }
      });
    }),

  sendClientTelemetry: async (subject: string, returnCode: number, content: string): Promise<void> =>
    new Promise((resolve, reject) => {
      const {socket} = SocketClient.getInstance();
      socket.emit('sendClientTelemetry', {subject, returnCode, content}, async (response) => {
        if (response.data && !response.data.error) {
          resolve();
        } else {
          if (response.data) {
            if (localFeatures.useSentry) {
              Sentry.setContext("Backend socket call failed: sendClientTelemetry", {
                data: JSON.stringify(response.data)
              });
              Sentry.captureException(new Error(response));
            }
            if (localFeatures.useTelemetry) {
              console.log(`Telemetry send failed for socket call: Subject ${subject}, ${JSON.stringify(response.data)}`);
            }
          }
          reject(response);
        }
      });
    })
}

export default socketRequests;
