<template>
  <v-dialog width="842" v-model="isOpen">
    <v-card class="auction-modal category-modal">
      <v-btn class="modal-close-btn icon-with-hover" icon @click="close()"><v-icon>fa-times</v-icon></v-btn>
      <v-card-text class="main-card">
        <v-container>
          <v-row align="center" class="form-container">
            <v-col align="center">
              <div class="form-title">{{ category && category.id ? $t('Update category') : $t('Add category') }}</div>
              <v-form>
                <form-lang-switcher class="mt-8 mb-7" @on-changed="langChanged" />
                <v-text-field
                  :label="$t('Name')"
                  placeholder=" "
                  class="input-field"
                  :value="getTranslatedText('name')"
                  @input="val => setTranslatableValue('name', val)"
                  :key="selectedLang"
                />

                <v-row class="mt-8" justify="space-between">
                  <v-btn @click="close" large outlined>
                    <span>{{ $t('Cancel') }}</span>
                  </v-btn>
                  <v-btn @click="doUpsert" large outlined>
                    <span>{{ $t('Save') }}</span>
                  </v-btn>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import useRootStore from '@/stores/rootStore';
import { AlertFlavor } from '@/api/enums';

export default {
  name: 'CreateAuction',
  props: {
    onUpsert: {
      required: true,
      type: Function
    }
  },
  data () {
    return {
      loading: false,
      isOpen: false,
      category: {},
      selectedLang: null
    }
  },
  computed: {
    ...mapState(useRootStore, ['appStoreLang'])
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT']),
    getTranslatedText(field) {
      if (!this.selectedLang || this.selectedLang === this.appStoreLang) {
        return this.category[field]
      } else {
        return this.category[`${field}_i18n`] ? this.category[`${field}_i18n`][this.selectedLang] : ''
      }
    },
    open(category) {
      this.category = { ...category };
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.clearData();
    },
    clearData() {
      this.loading = false;
      this.category = {};
    },
    async doUpsert() {
      this.loading = true;
      const res = await this.onUpsert(this.category);
      if (res) {
        const text = this.category.id ? this.$t('Category changed successfully') : this.$t('Category added successfully')
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: text })
        this.close();
      }
    },
    langChanged(lang) {
      this.selectedLang = lang
    },
    setTranslatableValue(field, val) {
      if (!this.selectedLang || this.selectedLang === this.appStoreLang) {
        this.category[field] = val
      } else {
        if (this.category[`${field}_i18n`]) {
          this.category[`${field}_i18n`] = {
            ...this.category[`${field}_i18n`],
            [this.selectedLang]: val
          }
        } else {
          this.category = {
            ...this.category,
            [`${field}_i18n`]: {
              [this.selectedLang]: val
            }
          }
        }
      }
    }
  }
}

</script>
<style lang="scss">
.category-modal {
  .v-input {
    .v-label {
      position: absolute;
      left: 0;
    }
    .v-text-field__slot {
      > span {
        flex: 1 1 auto;
        width: 100%;
      }
    }
  }
}
</style>
