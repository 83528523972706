<template>
  <div class="auction-container settings categories">
    <v-container>
      <div>
        <v-row align="center" class="form-container justify-center">
          <v-col sm="12" class="full-width">
            <v-row class="search-wrap">
              <v-col align="center">
                <div class="section-title mb-3">{{ $t('Categories') }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
          <template v-else>
            <v-col sm="12">
              <v-data-table
                :headers="disabled ? headers.slice(0, headers.length - 1) : headers"
                :items="categoriesView"
                :items-per-page="itemPerPage"
                class="categories-table px-4"
                :class="{disabled}"
                item-class="edit-article-row"
                sort-by="id"
                must-sort
                :no-data-text="$t('No categories available')"
                ref="table"
                @click:row="openCategoryModal"
                :key="key"
                dense
                :footer-props="{
                  'items-per-page-all-text': $t('All'),
                  'items-per-page-text': $t('Rows per page'),
                  'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                  'items-per-page-options': []
                }"
              >
                <template #[`item.updated_at`]="{ item }">
                  <span class="text-light">{{ getDate(item.updated_at) }}</span>
                </template>
                <template #[`item.created_at`]="{ item }">
                  <span class="text-light">{{ getDate(item.created_at) }}</span>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-icon small @click.stop="openCategoryModal(item)" class="icon icon-with-hover">
                    fa-edit
                  </v-icon>
                  <v-icon small @click.stop="removeCategory(item)" class="icon ml-2 icon-with-hover">
                    fa-trash-can
                  </v-icon>
                </template>

                <template #top>
                  <v-text-field
                    v-model="search"
                    append-icon="fa-magnifying-glass"
                    :label="$t('Search')"
                    hide-details
                    class="mb-3"
                  />
                </template>
              </v-data-table>
              <v-btn class="mt-6" @click="openCategoryModal()">
                <span>{{ $t('+ Add new category') }}</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </div>

      <create-category
        ref="createCategoryModal"
        :onUpsert="createNewCategory"
      />
      <confirmation-modal
        :title="$t('Do you really want to delete this category?')"
        @submit="submitRemove"
        @cancel="cancelRemove"
        ref="confirmationModal"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import updateData from '@/mixins/updateData'
import CreateCategory from './sections/create-category-modal'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import Vue from 'vue';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import { euNumbers, getTranslatedText } from '@/services/i18n';
import dispatcher from "@/api/dispatch";
import { AlertFlavor } from '@/api/enums';

export default {
  name: 'AuctionsOverview',
  components: {
    ConfirmationModal,
    CreateCategory
  },
  data: function () {
    return {
      currentAuction: {},
      search: '',
      confirmationDialog: false,
      headers: [
        {
          text: this.$t('ID'),
          value: 'id',
          sortable: true,
        },
        {
          text: this.$t('Name'),
          value: 'name',
        },
        {
          text: this.$t('Created at'),
          value: 'created_at',
        },
        {
          text: this.$t('Updated at'),
          value: 'updated_at',
        },
        {
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      searchFields: [
        {
          value: 'id'
        },
        {
          value: 'name'
        }
      ],
      itemPerPage: 10,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      articles: [],
      tmpArticles: [],
      loading: false,
      searchResult: [],
      categories: [],
      selectedCategory: null
    }
  },
  mixins: [
    updateData
  ],
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appLocalization', 'appSettings', 'appStoreLang', 'adminCategories']),
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    categoriesView() {
      if (this.search) {
        this.searchResult = []
        this.categories.forEach(el => {
          this.searchFields.forEach(val => {
            if (el[val.value] && el[val.value].toString().toLowerCase().includes(this.search.toLowerCase()) && this.searchResult.map(({ id }) => id).indexOf(el.id) === -1) {
              this.searchResult.push(el)
            }
          })
        })
        return this.searchResult;
      } else {
        return this.categories;
      }
    },
    disabled () {
      return !this.currentAuction
    },
    currency () {
      return this.appLocalization.currency
    },
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'fetchAdminCategories', 'SET_ADMIN_CATEGORIES', 'SET_TEMP_ALERT']),
    euNumbers,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },

    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    async getAuctions() {
      try {
        this.loading = true
        await this.fetchAdminCategories()
        this.categories = [...this.adminCategories]
      } catch (e) {
        this.error = true;
      }
      this.loading = false
    },
    getDate(date) {
      if (!date) return
      const d = new Date(date);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang || 'de', { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}`
    },
    searchHandler () {
      if (this.search) {
        this.categories = this.categories.filter(el => el.code.toString().includes(this.search) || el.name.includes(this.search) || el.description.toLowerCase().includes(this.search.toLowerCase()))
      }
    },

    openCategoryModal(category) {
      this.$refs.createCategoryModal.open(category);
    },

    removeCategory(category) {
      this.selectedCategory = category
      this.$refs.confirmationModal.openModal()
    },

    async createNewCategory(category) {
      try {
        if (category.id) {
          const result = await dispatcher.updateCategory(category)
          this.categories = this.categories.map(el => {
            if (el.id === result.id) {
              el = result
            }
            return el
          })
        } else {
          const result = await dispatcher.createCategory(category)
          this.categories = this.categories.concat(result)
        }
        this.SET_ADMIN_CATEGORIES(this.categories)
        return true;
      } catch (e) {
        this.error = true;
        if (e.data?.error) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t(e.data.data[0].errorShortText) })
        }
        return false;
      }
    },
    cancelRemove() {
      this.$refs.confirmationModal.closeModal()
      this.selectedCategory = null
    },
    async submitRemove() {
      try {
        await dispatcher.deleteCategory(this.selectedCategory.id)
        const idx = this.categories.findIndex(el => el.id === this.selectedCategory.id)
        this.categories.splice(idx, 1)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Category removed successfully') })
      } catch (e) {
        this.error = true;
        if (e.data?.error) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t(e.data.data[0].errorShortText) })
        }
      }
      this.selectedCategory = null
      this.$refs.confirmationModal.closeModal()
    },
  },
  async created() {
    await this.getAuctions()
  },
}
</script>

<style scoped lang="scss">
.section-title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 30px;
}
</style>
