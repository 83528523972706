import moment from 'moment';
import { AuctionFlavor } from '@/api/enums';

/**
 * filter auctions by code, name or description for the current language
 * @param {string} search - The search string
 * @param {any} auctions - The auctions array
 * @param {string} activeLang - The currently active language in 2-digit code i.e. 'en'
 * @return {<any>} - Filtered auctions
 */
export const searchAuctions = (search: string, auctions: any, activeLang: string) => {
  const result = auctions.filter(el => (el.code && el.code.toString().includes(search)) ||
    (el.name && el.name.toLowerCase().includes(search.toLowerCase())) ||
    (el.name_i18n && el.name_i18n[activeLang] && el.name_i18n[activeLang].toLowerCase().includes(search.toLowerCase())) ||
    (el.description_i18n && el.description_i18n[activeLang] && el.description_i18n[activeLang].toLowerCase().includes(search.toLowerCase())) ||
    (el.description && el.description.toLowerCase().includes(search.toLowerCase())))
  return result;
}

/**
 * sort auctions according to start_at descending or ascending
 * Sales are shown below live/timed auctions but only if they are started
 * @param {any[]} events - The auctions
 * @param {boolean} reverse - Sort reverse, so newest auctions are on top
 * @return {<any>} - Sorted auctions
 */
export const sortAuctions = (events: any[], reverse: boolean) => {
  const sortFunction = (a, b) => {
    const aTime = moment(a.start_at) as any
    const bTime = moment(b.start_at) as any
    return bTime - aTime
  }

  const sortFunctionReverse = (a, b) => {
    const aTime = moment(a.start_at) as any
    const bTime = moment(b.start_at) as any
    return aTime - bTime
  }

  let result = [];
  const sales = events.filter(e => e.type === AuctionFlavor.sale);
  const timedLive = events.filter(e => e.type !== AuctionFlavor.sale);
  (reverse) ? sales.sort(sortFunctionReverse) : sales.sort(sortFunction);
  (reverse) ? timedLive.sort(sortFunctionReverse):  timedLive.sort(sortFunction);
  result.push(...timedLive);
  result.push(...sales);

  return result;
}

/**
 * return a detailed image (/full) or if it doesn't exist the thumbnail (exists always)
 * @param {any} path - The path to the detailed image
 * @param {any} fallback - The path to the thumbnail image
 * @return {Promise<any>} - Promise containing the result
 */
export const getImageOrFallback = (path, fallback) => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = path;
    img.onload = () => resolve(path);
    img.onerror = () => resolve(fallback);
  });
}

/**
 * get the url for the detailed image (/full)
 * @param {any} url - The url to the thumbnail
 * @param {any} auctionCode - The auction code
 * @return {string} - The result
 */
export const getImageFullUrl = (url, auctionCode) => {
  return url.replace(auctionCode, `${auctionCode}/full`)
}
