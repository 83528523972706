<template>
  <div class="auction-container settings auctions">
    <div class="close-btn-wrapper">
      <v-btn class="close-btn sync mt-2 icon-with-hover" @click="syncData" :disabled="loading">
        <v-icon class="close-modal-icon">fa-sync</v-icon>
      </v-btn>
    </div>
    <v-container>
      <div>
        <v-row align="center" class="form-container justify-center pb-16">
          <v-col sm="12" class="full-width">
            <v-row class="search-wrap">
              <v-col align="center">
                <div class="section-title mb-3">{{ $t('Available auctions') }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
          <template v-else>
            <v-col sm="12" :key="tableKey">
              <v-data-table
                :headers="disabled ? headers.slice(0, headers.length - 1) : headers"
                :items="auctionsView"
                :items-per-page="itemPerPage"
                class="auctions-table elevation-1 px-4"
                :class="{disabled}"
                item-class="edit-article-row"
                sort-by="id"
                must-sort
                :no-data-text="$t('No auctions available')"
                ref="table"
                :key="key"
                dense
                :footer-props="{
                  'items-per-page-all-text': $t('All'),
                  'items-per-page-text': $t('Rows per page'),
                  'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                  'items-per-page-options': []
                }"
              >
                <template #[`header.count_registrations`]="{ header }">
                  <span class="text-light">{{ header.text }}</span>
                  <v-tooltip top allow-overflow>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ml-1 font-weight-regular"
                      >
                        fa-question-circle
                      </v-icon>
                    </template>
                    <span>{{ $t('explain_registrations') }}</span>
                  </v-tooltip>
                </template>
                <template #[`item.type`]="{ item }">
                  <span class="text-light">{{ $t(item.type.charAt(0).toUpperCase() + item.type.slice(1)) }}</span>
                </template>
                <template #[`item.status`]="{ item }">
                  <span class="text-light">{{ $t(item.status) }}</span>
                </template>
                <template #[`item.start_at`]="{ item }">
                  <span class="text-light">{{ getDate(item.start_at) }}</span>
                </template>
                <template #[`item.commission_bids_enabled`]="{ item }">
                  <v-icon v-if="item.commission_bids_enabled === false" class="red-color">fa-times</v-icon>
                  <v-icon v-else class="green-color-success">fa-check</v-icon>
                </template>
                <template #[`item.catalogue_enabled`]="{ item }">
                  <v-icon v-if="item.catalogue_enabled === 'disabled'" class="red-color">fa-times</v-icon>
                  <v-icon v-else class="green-color-success">fa-check</v-icon>
                </template>
                <template #[`item.post_auction_sale_enabled`]="{ item }">
                  <v-icon v-if="item.post_auction_sale_enabled === 'disabled'" class="red-color">fa-times</v-icon>
                  <v-icon v-else class="green-color-success">fa-check</v-icon>
                </template>
                <template #[`item.count_registrations`]="{ item }">
                  <p class="text-light ma-0" v-if="item.count_registrations">
                    {{ item.count_registrations.total }}
                    ( <a href="javascript:void(0)" @click.stop="openUsersModal(item, BidderNumberStatusType.pending)" v-if="item.count_registrations.pending > 0">{{ item.count_registrations.pending }}</a> <span v-else>{{ item.count_registrations.pending }}</span> |
                    <a href="javascript:void(0)" @click.stop="openUsersModal(item, BidderNumberStatusType.unlocked)" v-if="item.count_registrations.unlocked > 0">{{ item.count_registrations.unlocked }}</a> <span v-else>{{ item.count_registrations.unlocked }}</span> |
                    <a href="javascript:void(0)" @click.stop="openUsersModal(item, BidderNumberStatusType.locked)" v-if="item.count_registrations.locked > 0">{{ item.count_registrations.locked }}</a> <span v-else>{{ item.count_registrations.locked }}</span> )
                  </p>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-tooltip top allow-overflow>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs"v-on="on" @click.stop="startAuction(item.id)" class="icon start-icon mr-2 icon-with-hover" v-if="item.status === AuctionStatusType.stopped || item.status === AuctionStatusType.preparing">
                        fa-play
                      </v-icon>
                    </template>
                    <span>{{ $t('Start auction') }}</span>
                  </v-tooltip>
                  <v-tooltip top allow-overflow>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs"v-on="on" @click.stop="stopAuction(item.id)" class="icon stop-icon mr-2 icon-with-hover" v-if="item.status === AuctionStatusType.started || item.status === AuctionStatusType.paused">
                        fa-stop
                      </v-icon>
                    </template>
                    <span>{{ $t('Stop auction') }}</span>
                  </v-tooltip>
                  <v-tooltip top allow-overflow>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs"v-on="on" @click.stop="openAuctionArticles(item)" class="mr-2 icon-with-hover">
                        fa-list
                      </v-icon>
                    </template>
                    <span>{{ $t('Manage articles') }}</span>
                  </v-tooltip>
                  <v-tooltip top allow-overflow>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs"v-on="on" @click.stop="openCommissionBids(item)" class="mr-2 icon-with-hover" v-if="item.type === AuctionFlavor.live && moment().diff(item.active_until, 'seconds') <= 0 && item.catalogue_enabled !== 'disabled' && item.commission_bids_enabled">
                        fa-hammer
                      </v-icon>
                    </template>
                    <span>{{ $t('Manage commission bids') }}</span>
                  </v-tooltip>
                  <v-tooltip top allow-overflow>
                    <template #activator="{ on, attrs }">
                      <v-icon v-bind="attrs"v-on="on" @click.stop="openAuctionGeneral(item)" class="icon-with-hover">
                        fa-edit
                      </v-icon>
                    </template>
                    <span>{{ $t('Manage auction settings') }}</span>
                  </v-tooltip>
                </template>

                <template #top>
                  <v-text-field
                    v-model="search"
                    append-icon="fa-magnifying-glass"
                    :label="$t('Search')"
                    hide-details
                    class="mb-3"
                  />
                </template>
              </v-data-table>
              <v-btn class="mt-6" @click="openCreateAuctionModal()">
                <span>{{ $t('+ Add new auction') }}</span>
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </div>

      <view-auction-signup-modal ref="usersModal" @updateTable="updateTable" />

      <create-auction
        ref="createAuctionModal"
        :onUpsert="createNewAuction"
        :loading="createAuctionLoading"
      />
      <stop-auction-modal ref="stopAuctionModal" @submit="submitStopAuction" @close="activeAuctionId = null" :auction="auctions.find(el => el.id === activeAuctionId)" />
      <start-auction-modal ref="startAuctionModal" @submit="submitStartAuction" @close="activeAuctionId = null" :auction="auctions.find(el => el.id === activeAuctionId)" />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import CreateAuction from '@/components/modals/admin-create-auction-modal'
import ViewAuctionSignupModal from '@/components/modals/admin-view-auction-signups-modal.vue'
import StartAuctionModal from '@/components/modals/admin-auction-start-modal'
import StopAuctionModal from '@/components/modals/admin-auction-stop-modal'
import dispatcher from '@/api/dispatch';
import useRootStore from '@/stores/rootStore';
import { euNumbers, getTranslatedText } from '@/services/i18n';
import { AlertFlavor, AuctionFlavor, AuctionStatusType, BidderNumberStatusType } from '@/api/enums';

export default {
  name: 'AuctionsOverview',
  components: {
    CreateAuction,
    ViewAuctionSignupModal,
    StopAuctionModal,
    StartAuctionModal
  },
  data: function () {
    return {
      auctions: [],
      currentAuction: {},
      search: '',
      headers: [
        {
          text: this.$t('ID'),
          value: 'id',
          sortable: true,
          width: 70
        },
        {
          text: this.$t('Type'),
          value: 'type',
          sortable: true,
          width: 130
        },
        {
          text: this.$t('Code'),
          value: 'code',
          sortable: true,
          width: 75
        },
        {
          text: this.$t('Status'),
          value: 'status',
          align: 'left',
          sortable: true,
          filterable: false,
          width: 120
        },
        {
          text: this.$t('Articles'),
          value: 'count_articles',
          align: 'left',
          sortable: true,
          filterable: false,
          width: 50
        },
        {
          text: this.$t('Start date'),
          value: 'start_at',
          align: 'left',
          width: 200
        },
        {
          text: this.$t('Name'),
          value: 'name',
          align: 'left',
          width: 150
        },
        {
          text: this.$t('Registrations'),
          value: 'count_registrations',
          align: 'left',
          sortable: false,
          width: 125
        },
        {
          text: this.$t('Commission bids'),
          value: 'commission_bids_enabled',
          align: 'center',
          sortable: false,
          width: 40
        },
        {
          text: this.$t('Catalogue'),
          value: 'catalogue_enabled',
          align: 'center',
          sortable: false,
          width: 40
        },
        {
          text: this.$t('Post-auction sale'),
          value: 'post_auction_sale_enabled',
          align: 'center',
          sortable: false,
          width: 40
        },
        {
          text: this.$t('Actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: 140
        },
      ],
      searchFields: [
        {
          value: 'code'
        },
        {
          value: 'name'
        },
        {
          value: 'description'
        }
      ],
      itemPerPage: 10,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      articles: [],
      tmpArticles: [],
      createAuctionLoading: false,
      loading: false,
      searchResult: [],
      knockdowns: [],
      tableKey: 0,
      activeAuctionId: null
    }
  },
  computed: {
    AuctionStatusType() {
      return AuctionStatusType
    },
    AuctionFlavor() {
      return AuctionFlavor
    },
    BidderNumberStatusType() {
      return BidderNumberStatusType
    },
    ...mapState(useRootStore, ['userProfile', 'appLocalization', 'showKnockdownsModal', 'appSettings', 'appStoreLang']),
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    auctionsView() {
      if (this.search) {
        this.searchResult = []
        this.auctions.forEach(el => {
          this.searchFields.forEach(val => {
            if (el[val.value] && el[val.value].toString().toLowerCase().includes(this.search.toLowerCase()) && this.searchResult.map(({ id }) => id).indexOf(el.id) === -1) {
              this.searchResult.push(el)
            }
          })
        })
        return this.searchResult;
      } else {
        return this.auctions;
      }
    },
    disabled () {
      return !this.currentAuction
    },
    currency () {
      return this.appLocalization.currency
    },
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'SET_TEMP_ALERT']),
    euNumbers,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    async getAuctionDetail(auctionID) {
      try {
        this.loading = true;
        let responseAuction = await dispatcher.getAuctionData(auctionID)

        /// Integrate media url
        let auctionNumber = responseAuction.code
        let mediaServer = this.appLocalization.media_server_url

        let tmpArticles = responseAuction.articles.map((itm) => {
          let articleNumber = itm.number
          let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
          return { ...itm, image: imageUrl }
        })
        responseAuction.articles = tmpArticles

        this.currentAuction = responseAuction;

        this.clearErrors()
        this.loading = false;
      } catch (e) {
        this.error = true;
        this.loading = false;
      }
    },
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    async getAuctions(skipLoading = false) {
      try {
        this.loading = !skipLoading
        const data = await dispatcher.getAllAuctions();
        this.auctions = data
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      } finally {
        this.loading = false
      }
    },
    getDate(date) {
      if (!date) return
      const d = new Date(date);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang || 'de', { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}`
    },
    searchHandler () {
      if (this.search) {
        this.auctions = this.auctions.filter(el => el.code.toString().includes(this.search) || el.name.includes(this.search) || el.description.toLowerCase().includes(this.search.toLowerCase()))
      }
    },

    async openAuctionArticles(item) {
      this.$router.push({ name: 'Articles', params: { id: item.id } })
    },

    async openAuctionGeneral(item) {
      this.$router.push({ name: 'General', params: { id: item.id } })
    },

    openCommissionBids(item) {
      this.$router.push({ name: 'CommissionBids', params: { id: item.id } })
    },

    openCreateAuctionModal(auction) {
      this.$refs.createAuctionModal.open(auction);
    },

    async createNewAuction(auction) {
      try {
        this.createAuctionLoading = true;
        auction.post_auction_sale_enabled = !!auction.post_auction_sale_enabled
        if (auction.id) {
          const result = await dispatcher.updateAuction(auction.id, auction)
          this.auctions = this.auctions.map(el => {
            if (el.id === result.id) {
              el = result
            }
            return el
          })
        } else {
          const result = await dispatcher.createAuction(auction)
          this.auctions = this.auctions.concat(result)
        }
        this.createAuctionLoading = false;
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Auction created successfully') })
        this.$refs.createAuctionModal.close()
      } catch (e) {
        this.error = true;
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error creating the auction') })
        this.createAuctionLoading = false;
      }
    },
    async openUsersModal(item, state) {
      this.$refs.usersModal.openModal(item.id, item.require_bid_limit_on_signup, state)
    },
    async updateTable() {
      await this.getAuctions(true)
    },
    syncData() {
      this.getAuctions(true)
    },
    startAuction(id) {
      this.$refs.startAuctionModal.openModal()
      this.activeAuctionId = id
    },
    stopAuction(id) {
      this.$refs.stopAuctionModal.openModal()
      this.activeAuctionId = id
    },
    async submitStopAuction(force) {
      try {
        const payload = {
          auctionId: this.activeAuctionId,
          force
        }
        await dispatcher.stopAuction(payload)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('The auction/sale has been stopped successfully') })
        this.$refs.stopAuctionModal.closeModal()
        await this.updateTable()
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error stopping the auction/sale') })
      }
    },
    async submitStartAuction() {
      try {
        await dispatcher.startAuction(this.activeAuctionId)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('The auction/sale has been started successfully') })
        const auction = this.auctions.find(el => +el.id === +this.activeAuctionId)
        this.$refs.startAuctionModal.closeModal()
        if (auction.type === AuctionFlavor.live) {
          this.$router.push('/auction-admin')
        } else {
          await this.updateTable()
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error starting the auction/sale') })
      }
    },
  },
  async created() {
    await this.getAuctions()
  }
}
</script>

<style scoped>

</style>
