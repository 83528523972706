<template>
  <v-card class="auction-container settings auctions pb-8" flat>
    <div class="close-btn-wrapper">
      <v-btn class="close-btn" @click="closeModal()">
        <v-icon class="close-modal-icon">fa-times</v-icon>
      </v-btn>
      <v-btn class="save-btn" :disabled="validationError || bidstepsError" @click="saveData">
        <v-icon class="save-modal-icon">fa-save</v-icon>
      </v-btn>
    </div>
    <v-progress-circular indeterminate color="primary" class="loading-icon mx-auto mt-8 d-block" v-if="dataLoading" />
    <v-card-text v-else-if="!auctionIsEmpty">
      <v-container>
        <div>
          <v-row align="center" class="search-wrap justify-center">
            <v-col sm="12" class="full-width">
              <v-row>
                <v-col sm="7">
                  <AuctionSettingsForm
                    :currentAuction="currentAuction"
                    :checkValidation="checkValidation"
                    @setValidation="e => validationError = e"
                  />
                  <div
                    class="overview-checkimages-container row align-center justify-space-between"
                    v-if="!!currentAuction && currentAuction.id > 0"
                  >
                    <v-btn color="success" class="mb-2" @click="validateArticlesImages">
                      <span>{{ $t('Validate article images') }}</span>
                    </v-btn>

                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="loading-icon"
                      v-if="loading"
                    />

                    <v-icon color="#4CAF50" v-if="!loading && validateImages && listArticlesNoImages.length <= 0">
                      check_circle
                    </v-icon>

                    <v-btn class="text-none mb-2" v-if="!loading && validateImages && listArticlesNoImages.length > 0" text @click="openImagesErrorModal">
                      <v-icon left color="red">fa-times</v-icon>
                      {{ $t('Errors detected') }}
                    </v-btn>

                    <v-dialog
                      v-model="articleImagesErrorModal"
                      width="700"
                    >
                      <template #activator="{ on, attrs }">
                        <v-icon
                          class="open-modal-errors" dark
                          v-bind="attrs"
                          v-on="on" color="#E6B714" v-show="!loading && listArticlesNoImages.length > 0"
                        />
                      </template>

                      <v-card height="500" class="overflow-y-auto">
                        <div v-if="!validateImageError">
                          <v-card-title class="headline grey lighten-2">
                            {{ $t('List of articles with no images') }}
                          </v-card-title>

                          <v-card-text>
                            <div
                              class="overview-checkimages-container"
                              v-if="!!currentAuction && listArticlesNoImages.length && currentAuction.id > 0"
                            >
                              <ul>
                                <li v-for="(art,idx) in listArticlesNoImages" :key="idx">
                                  <span>{{ $t('Article') }} #</span> {{ getArticleNumber(art) }} -
                                  <span class="article-name"> {{ art.articleName }} </span>
                                </li>
                              </ul>
                            </div>
                          </v-card-text>
                        </div>
                        <div v-if="validateImageError">
                          <v-card-text>
                            <div class="overview-checkimages-container">
                              {{ validateImageErrorMsg }}
                            </div>
                          </v-card-text>
                        </div>

                      </v-card>
                    </v-dialog>
                  </div>
                  <div class="my-2">
                    <span class="text-subtitle-1 mr-2">{{ $t('Exports') }}</span>
                    <v-btn class="success mr-2" @click="downloadUserExport(currentAuction.code)">
                      <span>{{ $t('Export users') }}</span>
                    </v-btn>
                    <v-btn class="success mr-2" :disabled="isExportButtonDisabled" @click="downloadCommissionBidExport(currentAuction.code)">
                      <span>{{ $t('Export commission bids') }}</span>
                    </v-btn>
                    <v-btn class="success" :disabled="isExportButtonDisabled" @click="downloadKnockdownExport(currentAuction.code)">
                      <span>{{ $t('Export knockdowns') }}</span>
                    </v-btn>
                  </div>
                </v-col>
                <v-col sm="5">
                  <bid-steps
                    :disabled="!currentAuction"
                    :currentAuction="currentAuction"
                    @onChange="onChangeBidSteps"
                    @onImportBidSteps="handleImportBidSteps"
                    :key="currentAuction.id"
                    v-if="currentAuction.type !== AuctionFlavor.sale"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card-text>

    <dialog-confirm
      ref="dialog"
      @onOk="handleOk()"
      :cancelLabel="cancelLabel"
      :key="cancelLabel"
    />
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState } from 'pinia'
import BidSteps from './sections/bid-steps'
import AuctionSettingsForm from './sections/auction-settings'
import DialogConfirm from '@/components/dialog/dialog-confirm'
import dispatcher from '@/api/dispatch';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import moment from 'moment';
import { AlertFlavor, AuctionFlavor, AuctionStatusType } from '@/api/enums';

export default {
  name: 'AuctionsSetting',
  components: {
    BidSteps,
    AuctionSettingsForm,
    DialogConfirm
  },
  async created() {
    this.dataLoading = true
    await this.getAuctions()
    const categoriesData = await dispatcher.getCategories(false)
    this.categories = categoriesData
    if (this.$route.params.id) {
      const auctionData = await dispatcher.getAuctionData(this.$route.params.id)
      this.knockdowns = await this.getAdminKnockdownsPerAuction(this.$route.params.id)
      this.currentAuction = auctionData
    }
    this.UPDATE_GLOBAL_STATE({
      key: 'appLoading',
      value: true,
    })
    this.dataLoading = false
  },
  data: function () {
    return {
      auctions: null,
      loading: false,
      dialogType: null,
      dialogMessage: null,
      dialogHeader: null,
      currentDeleteArticle: null,
      listArtcilesNoImages: [],
      validateImageError: false,
      articleImagesErrorModal: false,
      cancelLabel: this.$t('Cancel'),
      isAuctionStarted: false,
      articleErrors: [],
      newBidSteps: [],
      categories: [],
      currentAuction: null,
      validationError: true,
      knockdowns: [],
      listArticlesNoImages: [],
      validateImages: false,
      validateImageErrorMsg: '',
      bidStepsChanged: false,
      checkValidation: false,
      bidstepsError: false,
      dataLoading: true
    }
  },
  computed: {
    AuctionFlavor() {
      return AuctionFlavor
    },
    ...mapState(useRootStore, ['appLocalization', 'showAuctionModal']),
    isDisableStartBtn() {
      return this.isAuctionStarted
    },
    isExportButtonDisabled() {
      return _.isEmpty(this.currentAuction) || (this.currentAuction.articles && this.currentAuction.articles.length === 0)
    },
    mergedArticles() {
      if (typeof this.knockdowns === 'string') return this.currentAuction.articles
      const articles = this.currentAuction.articles.map(article => {
        let data = {
          ...article
        }
        const target = this.knockdowns.find(el => article.id === el.articles_id.id)
        if (target) {
          data = {
            ...data,
            knockdown: target.articles_id.sold_price,
            bidder_num: target.auction_users_id?.bidder_number,
            type: target.articles_id.live_bidder
          }
        }
        return data
      })
      return articles
    },
    auctionIsEmpty() {
      return _.isEmpty(this.currentAuction)
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['getAdminKnockdownsPerAuction']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_TEMP_ALERT']),
    getArticleNumber(itm) {
      return itm.number_optional || itm.article.toString()
    },
    async downloadKnockdownExport(auctionCode) {
      try {
        await dispatcher.exportKnockdowns(`knockdowns-export-${moment().format('DDMMYYYY-HH-mm-ss')}.csv`, auctionCode)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Export succeeded') })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error during the export') })
      }
    },
    async downloadUserExport(auctionCode) {
      try {
        await dispatcher.exportUsers(`users-export-${moment().format('DDMMYYYY-HH-mm-ss')}.csv`, auctionCode)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Export succeeded') })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error during the export') })
      }
    },
    async downloadCommissionBidExport(auctionCode) {
      try {
        await dispatcher.exportCommissionBids(`commissionBids-export-${moment().format('DDMMYYYY-HH-mm-ss')}.csv`, auctionCode)
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Export succeeded') })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error during the export') })
      }
    },
    async getAuctions() {
      try {
        this.loading = true
        this.isAuctionStarted = false
        const data = await dispatcher.getAllAuctions();
        this.auctions = data
        this.isAuctionStarted = data.some(itm => (itm.status === AuctionStatusType.paused || itm.status === AuctionStatusType.started) && itm.type === AuctionFlavor.live)

        this.loading = false;
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      } finally {
        this.loading = false;
      }

      const startedAuction = this.auctions.find(a => a.status === AuctionStatusType.started && a.type === AuctionFlavor.live);
      if (startedAuction) {
        await this.getAuctionDetail(startedAuction.id);
      }
    },

    async getCategories() {
      const categoriesData = await dispatcher.getCategories(false)
      this.categories = categoriesData
    },

    async getAuctionDetail(auctionID) {
      try {
        this.loading = true;
        let responseAuction = await dispatcher.getAuctionData(auctionID)

        /// Integrate media url
        let auctionNumber = responseAuction.code
        let mediaServer = this.appLocalization.media_server_url

        responseAuction.articles = responseAuction.articles.map((itm) => {
          let articleNumber = itm.number
          let imageUrl = `${mediaServer}/${auctionNumber}/${articleNumber}.jpg`
          return { ...itm, image: imageUrl }
        })

        this.currentAuction = responseAuction;

        this.clearErrors()
        this.loading = false;
      } catch (e) {
        this.error = true;
        this.loading = false;
      }
    },

    clearErrors() {
      this.listArtcilesNoImages = []
      this.validateImageError = false
      this.articleImagesErrorModal = false
    },

    handleImportBidSteps(newBidSteps, ...args) {
      this.newBidSteps = newBidSteps
      this.openDialog(true, 'importBidSteps', ...args);
    },

    async importBidSteps() {
      this.cancelLabel = this.$t('Ok')
      if (this.newBidSteps && this.newBidSteps.length > 0) {
        try {
          this.loading = true;
          for (let bidStep of this.currentAuction.bid_steps) {
            await dispatcher.removeBidStep(this.currentAuction.id, bidStep.id)
          }
          this.currentAuction.bid_steps = await dispatcher.updateBidStep(this.currentAuction.id, this.newBidSteps)
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Importing bid-steps succeeded') })
        } catch (e) {
          this.error = true;
          if (e.data?.error) {
            this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t(e.data.data[0].errorShortText) })
          }
        }
        this.loading = false;
        this.newBidSteps = null;
      }
    },

    onChangeBidSteps(error) {
      this.bidStepsChanged = true
      this.checkValidation = true
      this.$nextTick(() => {
        this.checkValidation = false
      })
      this.bidstepsError = error
    },

    openDialog(isCancel = true, section, dialogType = 'confirm', ...args) {
      this.dialogFor = section;
      this.cancelLabel = this.$t('Cancel')
      if (!isCancel) { this.cancelLabel = this.$t('Ok') }

      this.$refs.dialog.open(dialogType, ...args);
    },

    openImagesErrorModal() {
      this.articleImagesErrorModal = true
    },

    handleOk() {
      const actions = {
        importBidSteps: this.importBidSteps
      }
      if (actions[this.dialogFor]) actions[this.dialogFor]()
    },

    closeModal() {
      this.$router.push({ name: 'Auctions' })
    },

    async validateArticlesImages() {
      this.validateImages = false
      this.loading = true
      try {
        await dispatcher.updateImageCache(this.currentAuction.id)
      } catch (e) {
        if (e.data?.error) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t(e.data.data[0].errorShortText) })
        }
        return;
      }
      try {
        this.listArticlesNoImages = await dispatcher.validateImages(this.currentAuction.id)
        this.validateImages = true
        this.loading = false
        this.validateImageError = false
      } catch (e) {
        this.loading = false
        this.validateImages = false
        this.validateImageError = true
        if (e.data?.error) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t(e.data.data[0].errorShortText) })
          this.validateImageErrorMsg = this.$t(e.data.data[0].errorShortText)
        }
      }
    },

    getRawObject(vueObj) {
      return JSON.parse(JSON.stringify(vueObj));
    },
    validateBidSteps(steps) {
      for (let i = 0; i < steps.length; i++) {
        const item = steps[i];
        if (!_.isEmpty(item.start) || !_.isEmpty(item.bid_step)) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: `${this.$t('All fields are required')}` })
          return false;
        }

        if (item.bid_step === 0) {
          this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: `${this.$t('Bid step cannot be 0')}` })
          return false;
        }
      }
      /// Prevent duplicates bid steps
      const lookup = steps.reduce((a, e) => {
        a[e.start] = ++a[e.start] || 0
        return a
      }, {})

      const bidStepsDuplicates = steps.filter(e => lookup[e.start])
      if (bidStepsDuplicates.length > 0) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: `${this.$t('Duplicated bid steps are not allowed')}` })
        return false;
      }
      return true;
    },

    async saveData() {
      try {
        const copyWithoutArticles = JSON.parse(JSON.stringify(this.currentAuction))
        delete (copyWithoutArticles.articles)
        delete (copyWithoutArticles.bid_steps)
        const payload = {
          ...copyWithoutArticles,
          active_until: this.currentAuction.active_until,
          type: this.currentAuction.type,
          require_bid_limit_on_signup: this.currentAuction.require_bid_limit_on_signup,
          banner_image: this.currentAuction.banner_image,
          catalogue_enabled: this.currentAuction.catalogue_enabled,
          code: this.currentAuction.code,
          post_auction_sale_enabled: this.currentAuction.type === AuctionFlavor.timed ? false : this.currentAuction.post_auction_sale_enabled,
          commission_bids_enabled: this.currentAuction.type === AuctionFlavor.timed ? false : this.currentAuction.commission_bids_enabled,
          description: this.currentAuction.description,
          description_i18n: this.currentAuction.description_i18n,
          start_at: this.currentAuction.start_at,
          name: this.currentAuction.name,
          name_i18n: this.currentAuction.name_i18n,
          preview_data: this.currentAuction.preview_data,
          sale_dates: this.currentAuction.sale_dates,
          shipping_data: this.currentAuction.shipping_data,
          shipping_data_i18n: this.currentAuction.shipping_data_i18n,
          surcharge: +this.currentAuction.surcharge,
          tax: +this.currentAuction.tax,
        }
        await dispatcher.updateAuction(this.currentAuction.id, payload);

        const res = this.validateBidSteps(this.currentAuction.bid_steps)
        if (!res) this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('Bid steps validation fails') })
        if (res && this.bidStepsChanged) {
          const bidSteps = this.currentAuction.bid_steps.map(bidStep => ({ start: bidStep.start, bid_step: bidStep.bid_step }))
          await dispatcher.updateBidStep(this.currentAuction.id, bidSteps)
          this.bidStepsChanged = false
        }
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.success, content: this.$t('Auction changed successfully') })
        this.closeModal()
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error changing the auction') })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.auction-container {
  &.settings.auctions {
    .close-btn-wrapper {
      top: 110px;
    }
  }
}
</style>
