<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div class="bid-transactions-modal">
      <div class="bid-transactions-container">
        <div class="close-btn-wrapper">
          <v-btn class="close-btn mb-2" @click="$router.go(-1)">
            <v-icon class="close-modal-icon">fa-times</v-icon>
          </v-btn>
          <v-btn class="close-btn sync icon-with-hover" @click="syncData" :disabled="loading">
            <v-icon class="close-modal-icon">fa-sync</v-icon>
          </v-btn>
        </div>
        <v-col sm="12" class="full-width">
          <v-row class="search-wrap">
            <v-col align="center">
              <div class="page-title my-3">{{ $t('Bid transactions') }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-card class="main-card" v-if="auctions" flat>
          <v-container>
            <div>
              <v-row align="center" class="form-container justify-center">
                <v-col sm="12" class="full-width">
                  <v-col class="main-col-wrapper">
                    <v-col align="center" class="sub-col-wrapper">
                      <v-row>
                        <search-box
                          v-if="auctions && auctions.length > 0"
                          :currentAuction="currentAuction"
                          :auctions="auctions"
                          hide-create-new
                          hide-title
                          @onSelectAuction="getAuctionDetail"
                          key="auction-search"
                          style="margin-top: 6px !important;"
                        />
                        <v-row class="search-wrap ml-2 mt-0" v-if="!_.isEmpty(currentAuction)">
                          <v-col align="center" class="mt-1">
                            <v-text-field
                              class="input-field input-field-bigger-icon mt-0"
                              v-model="articleId"
                              append-icon="fa-magnifying-glass"
                              :label="$t('Search for article')"
                              hide-details
                              @input="getArticleDetail"
                              ref="search"
                              @keydown="inputNumberHandler"
                            />
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-col>
                <v-progress-circular v-if="loading && !error" class="loading-icon mt-6" indeterminate />
                <template v-else-if="!error">
                  <v-col sm="12" v-if="!_.isEmpty(currentAuction)" class="pb-16 mb-4">
                    <v-data-table
                      :headers="headers"
                      :items="tmpArticles"
                      :items-per-page="itemPerPage"
                      class="knockdown-table pb-16 pb-sm-0"
                      item-class="edit-article-row"
                      sort-by="id"
                      must-sort
                      ref="table"
                      :no-data-text="$t('No bid transactions found')"
                      :key="key"
                      dense
                      :footer-props="{
                        'items-per-page-all-text': $t('All'),
                        'items-per-page-text': $t('Rows per page'),
                        'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                        'items-per-page-options': [50, 100, 150, 200]
                      }"
                    >
                      <template #[`item.id`]="{ item }">
                        <span class="text-light" :class="{ 'text-bold': item.is_knockdown === 1, 'text-decoration-line-through': item.status === '-1' }">{{ item.id }}</span>
                      </template>
                      <template #[`item.number`]="{ item }">
                        <span class="text-light" :class="{ 'text-bold': item.is_knockdown === 1, 'text-decoration-line-through': item.status === '-1' }">{{ item.number }}</span>
                      </template>
                      <template #[`item.bid`]="{ item }">
                        <span class="text-light" :class="{ 'text-bold': item.is_knockdown === 1, 'text-decoration-line-through': item.status === '-1' }">{{ currency }} {{ euNumbers(item.bid) }}</span>
                      </template>
                      <template #[`item.status`]="{ item }">
                        <span class="text-light" :class="{ 'text-bold': item.is_knockdown === 1, 'text-decoration-line-through': item.status === '-1' }">
                          {{ item.status === '-1' ? $t('Deleted') : item.status === '0' ? $t('Placed') : $t('Overridden') }}
                        </span>
                      </template>
                      <template #[`item.is_knockdown`]="{ item }">
                        <v-icon v-if="item.is_knockdown === 0">fa-times</v-icon>
                        <v-icon v-else>fa-check</v-icon>
                      </template>
                      <template #[`item.type`]="{ item }">
                        <span class="text-light" :class="{ 'text-bold': item.is_knockdown === 1, 'text-decoration-line-through': item.status === '-1' }">{{ $t(item.type.charAt(0).toUpperCase() + item.type.slice(1)) }}</span>
                      </template>
                      <template #[`item.bidder_number`]="{ item }">
                        <span class="text-light" :class="{ 'text-bold': item.is_knockdown === 1, 'text-decoration-line-through': item.status === '-1' }">{{ item.bidder_number }}</span>
                      </template>
                      <template #[`item.created_at`]="{ item }">
                        <span class="text-light" :class="{ 'text-bold': item.is_knockdown === 1, 'text-decoration-line-through': item.status === '-1' }">{{ getDate(item.created_at) }}</span>
                      </template>
                      <template #[`item.updated_at`]="{ item }">
                        <span class="text-light" :class="{ 'text-bold': item.is_knockdown === 1, 'text-decoration-line-through': item.status === '-1' }">{{ getDate(item.updated_at) }}</span>
                      </template>
                    </v-data-table>
                  </v-col>
                  <div class="full-width mt-6" v-else-if="auctions && _.isEmpty(auctions)">
                    <div class="info-text" style="max-width: 400px;">
                      <v-icon>fa-circle-info</v-icon>
                      <p>{{ $t('No auctions found') }}</p>
                    </div>
                  </div>
                </template>
                <v-col sm="12" class="text-center" v-else>
                  <h3 class="no-data-text">{{ $t(error) }}</h3>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <Footer />
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import Footer from '@/components/footer/index'
import SearchBox from '@/views/settings/articles/sections/search'
import dispatcher from '@/api/dispatch';
import useRootStore from '@/stores/rootStore'
import { euNumbers, getTranslatedText } from '@/services/i18n';
import { AlertFlavor } from '@/api/enums';

export default {
  name: 'BidTransactions',
  components: {
    Footer,
    SearchBox,
  },
  data: function () {
    return {
      renderComponent: true,
      showProfileModal: false,
      modalPosition: false,
      profileLocalization: {},
      currentAuction: {},
      currentArticle: {},
      headers: [
        {
          text: this.$t('ID'),
          value: 'id',
          sortable: false
        },
        {
          text: this.$t('Article'),
          value: 'number',
          sortable: false
        },
        {
          text: this.$t('Bid'),
          value: 'bid',
          sortable: false
        },
        {
          text: this.$t('Status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$t('Knockdown'),
          value: 'is_knockdown',
          sortable: false
        },
        {
          text: this.$t('Type'),
          value: 'type',
          sortable: false
        },
        {
          text: this.$t('Bidder number'),
          value: 'bidder_number',
          sortable: false
        },
        {
          text: this.$t('Created at'),
          value: 'created_at',
          sortable: false
        },
        {
          text: this.$t('Updated at'),
          value: 'updated_at',
          sortable: false
        }
      ],
      itemPerPage: 50,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      articles: [],
      tmpArticles: [],
      loading: false,
      auctions: null,
      articleId: null
    }
  },
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appLocalization', 'appSettings', 'appStoreLang']),
    emptyFields() {
      return false
    },
    currency () {
      return this.appLocalization.currency
    },
  },
  created() {
    this.getAuctions()
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile']),
    euNumbers,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    // Close profile modal without saving
    closeProfileModal () {
      this.$router.push({ name: 'dashboard' })
    },

    async getAuctionDetail(auctionID) {
      this.loading = true
      this.page = 0
      try {
        let responseAuction = await dispatcher.getBidTransactions(auctionID)
        if (responseAuction.Message) {
          this.error = responseAuction.Message
          return
        }

        this.error = null
        this.currentAuction.id = auctionID
        this.articles = responseAuction
        this.tmpArticles = responseAuction
        this.key++
      } catch (e) {
        this.currentAuction.id = {}
        this.articles = []
        this.tmpArticles = []
        this.error = this.$t('No bid transactions found')
      }
      this.loading = false
    },
    getArticleDetail(id) {
      if (id) {
        const target = this.articles.filter(el => el.number === id || el.number_optional === id)
        this.tmpArticles = [...target]
      } else {
        this.tmpArticles = [...this.articles]
      }
    },
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },

    async getAuctions() {
      try {
        const data = await dispatcher.getAllAuctions();
        this.auctions = data
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: AlertFlavor.error, content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },

    changePerPage(item) {
      this.itemPerPage = item
      this.page = 0
      this.getArticlesData()
    },

    getDate(date) {
      if (!date) return
      const d = new Date(date);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang || 'de', { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}:${s <= 9 ? '0' + s : s}`
    },

    async syncData() {
      await this.getAuctionDetail(this.currentAuction.id)
      this.getArticleDetail(this.articleId)
    },

    inputNumberHandler (e) {
      const expr = /^[0-9]*$/
      // allow the following keys to be able to still use the application properly
      if (!expr.test(e.key) && e.key !== 'Tab' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Backspace' && e.key !== 'Delete') {
        e.preventDefault()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bid-transactions-container {
  .close-btn-wrapper {
    position: fixed;
    top: 110px;
    right: 40px;
    z-index: 1;

    .v-ripple__container {
      display: none !important;
    }

    @media(max-width: 968px) {
      top: 90px;
      right: 10px;
    }

    .close-text {
      @media(max-width: 968px) {
        display: none;
      }
    }

    .close-modal-icon {
      font-size: 24px;
      width: 24px;
      height: 24px;
      background: transparent;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: all .3s ease;
    }

    button {
      position: static !important;
      text-transform: none;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      background-color: transparent !important;
      box-shadow: none;
      letter-spacing: unset !important;
      @media(max-width: 968px) {
        width: 40px;
        min-width: 40px !important;
        height: 40px;
        padding: 0 !important;
      }

      &:before, &:focus:before, &:hover:before {
        background-color: transparent !important;
      }

      i {
        @media(max-width: 968px) {
          font-size: 20px !important;
          margin-left: 0 !important;
        }
      }
    }
  }
}
.page-title {
  font-size: 24px;
  line-height: 25px;
  font-weight: 400;
}
.article-name {
  font-size: 12px;
  -webkit-line-clamp: 1;
  @media (max-width: 968px) {
    white-space: pre-wrap;
    -webkit-line-clamp: unset;
  }
}
</style>
